/* Content container styles */

* {
    box-sizing: border-box;
}

*::before, *::after {
    box-sizing: border-box;
}

#main-wrapper-test {
    height: 100vh;
}

@media screen and (min-width: 992px) {
    #main-wrapper {
        min-height: 100vh;
        overflow: hidden;
        display: block;
        position: relative;
        padding-bottom: 7.5vw;
        display: flex;
        flex-flow: column;
        height: 100%;
        background-color: #f8f8ff;
    }
    #content-container {
        margin: 0;
        padding: 0;
        padding-top: 4.7vw;
        padding-bottom: 3.5vw;
        flex-grow: 1;
        background-color: #f8f8ff;
    }
    #content-container-test {
        margin: 0;
        padding: 0;
        height: 100vh;
    }
}

@media screen and (max-width: 992px) {
    #main-wrapper {
        min-height: 100vh;
        overflow: hidden;
        display: block;
        position: relative;
        padding-bottom: 65vw;
        display: flex;
        flex-flow: column;
        height: 100%;
        background-color: #f8f8ff;
    }
    #content-container {
        margin: 0;
        padding: 0;
        padding-top: 20vw;
        flex-grow: 1;
        background-color: #f8f8ff;
    }
    #content-container-test {
        margin: 0;
        padding: 0;
        height: 100vh;
    }
    #content-container-test>div {
        margin: 0;
        padding: 0;
    }
}

/* Import font families */

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orienta&display=swap');
/* Common */

.form-label {
    font-size: 0.85rem;
    color: #515fc9;
    font-weight: 500;
}

.invalid-feedback {
    display: block;
}