/* PATIENT LIST (MEDICAL STAFF VERSION) STYLES */

/* Container */

#profile_page_medical_staff_version_navigation_profile_list_container {
  height: 100%;
  width: 100%;
  padding: 0;
}

#profile_page_medical_staff_version_navigation_profile_list_parent_container {
  display: table;
  height: 100%;
  width: 100%;
  background-color: white;
  outline: 0.175vw solid #515fc9;
}

#profile_page_medical_staff_version_navigation_profile_list_child_container {
  display: table-cell;
  height: 100%;
}

#profile_page_medical_staff_version_navigation_profile_list_subparent_container {
  display: block;
  height: 100%;
  width: 100%;
  display: table;
}

/* List header delete button styles */

#profile_page_medical_staff_version_navigation_profile_list_button_container {
  width: 100%;
  height: 5%;
  display: table-row;
  background-color: #eaf8ff;
  text-align: center;
}

#profile_page_patient_version_navigation_profile_list_button_new {
  font-size: 2vw;
}

#profile_page_medical_staff_version_navigation_profile_list_button_delete {
  font-size: 2vw;
}

#patient_search {
  margin: 15px 0 20px 0;
}
#patient_search_id {
  font-family: "Montserrat", sans-serif !important;
  font-size: 1vw;
  border: 2px solid #515fc9;
}
#patient_search_error {
  font-family: "Montserrat", sans-serif !important;
  font-size: 0.8vw;
  color: red;
  margin: 0 0 20px 0;
}

/* Test battery list styles */

#profile_page_medical_staff_version_navigation_profile_list {
  width: 100%;
  height: 95%;
  display: table-row;
}

#profile_page_medical_staff_version_navigation_profile_list_content_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

#profile_page_medical_staff_version_navigation_profile_list_content {
  overflow-y: scroll;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

#profile_page_medical_staff_version_navigation_profile_list_content > ul {
  list-style: none;
  padding: 0;
}

#profile_page_medical_staff_version_navigation_profile_list_content > ul > li {
  font-size: 1.15vw;
  font-family: "Montserrat", sans-serif;
  padding: 0.5vw 0.75vw 0.5vw 0.75vw;
  border-bottom: 0.01vw solid #515fc9;
}

.profile_page_medical_staff_version_navigation_profile_list_object:hover {
  cursor: pointer;
}

/* New patient modal styles */

.new_patient_profile_modal_error {
  font-size: 0.925vw;
  font-family: "Montserrat", sans-serif;
  color: red;
}

#new_patient_profile_modal_create_button {
  width: 100%;
  font-size: 1.325vw;
  font-weight: 500;
  background-color: #515fc9;
  color: #f8f8ff;
  padding: 0.625vh 2.25vw 0.625vh 2.25vw;
  border-radius: 5vw;
  border-color: transparent;
}

#new_patient_profile_modal_create_button:hover {
  width: 100%;
  font-size: 1.325vw;
  font-weight: 500;
  background-color: #f8f8ff;
  color: #515fc9;
  padding: 0.625vh 2.25vw 0.625vh 2.25vw;
  border-radius: 5vw;
  border-color: transparent;
}

#new_patient_profile_modal_close_button {
  width: 100%;
  font-size: 1.325vw;
  font-weight: 500;
  background-color: #f8f8ff;
  color: #515fc9;
  padding: 0.625vh 2.25vw 0.625vh 2.25vw;
  border-radius: 5vw;
  border-color: transparent;
}

#new_patient_profile_modal_close_button:hover {
  width: 100%;
  font-size: 1.325vw;
  font-weight: 500;
  background-color: #515fc9;
  color: #f8f8ff;
  padding: 0.625vh 2.25vw 0.625vh 2.25vw;
  border-radius: 5vw;
  border-color: transparent;
}
