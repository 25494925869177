/* CONVERSATION TEST STEP STYLES */

/* Container */

#test_battery_test_step_03_conversation_container {
    display: flex;
    flex-flow: column;
    height: 100%;
    flex: auto;
}

/* Recording button */

#test_battery_test_step_03_conversation_button_container {
    height: 50%;
}

@media screen and (min-width: 992px) {
    #test_battery_test_step_03_conversation_stop_button {
        background-color: transparent;
        border: none;
    }
    #test_battery_test_step_03_conversation_stop_button>svg {
        font-size: 7em;
        color: #f7130a;
    }
    #test_battery_test_step_03_conversation_stop_button>svg:hover {
        font-size: 7em;
        color: #910000;
    }
    #test_battery_test_step_03_conversation_stop_button_interstitial {
        font-size: 7em;
        color: transparent;
        background-color: transparent;
        border: none;
    }
    #test_battery_test_step_03_conversation_stop_button_interstitial:hover {
        font-size: 7em;
        background-color: transparent;
        color: transparent;
        border: none;
    }
    #test_battery_test_step_03_conversation_stop_button_interstitial>svg:active {
        font-size: 7em;
        background-color: transparent;
        color: transparent;
        border: none;
    }
}

@media screen and (max-width: 992px) {
    #test_battery_test_step_03_conversation_stop_button {
        background-color: transparent;
        border: none;
    }
    #test_battery_test_step_03_conversation_stop_button>svg {
        font-size: 7em;
        color: #f7130a;
    }
    #test_battery_test_step_03_conversation_stop_button>svg:hover {
        font-size: 7em;
        color: #910000;
    }
    #test_battery_test_step_03_conversation_stop_button_interstitial>svg {
        font-size: 7em;
        color: transparent;
        background-color: transparent;
        border: none;
    }
    #test_battery_test_step_03_conversation_stop_button_interstitial>svg:hover {
        font-size: 7em;
        background-color: transparent;
        color: transparent;
        border: none;
    }
    #test_battery_test_step_03_conversation_stop_button_interstitial>svg:active {
        font-size: 7em;
        background-color: transparent;
        color: transparent;
        border: none;
    }
}