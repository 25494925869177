/* HOME HEADER STYLES */

/* Container */

#home_header_section_container {
    flex: 1;
    width: 100%;
    position: relative;
    background-color: #B4C6FB;
    margin: 0;
    padding: 0;
}

/* Header video */

#home_header_section_video {
    width: 100%;
    height: 100%;
}

#home_header_section_video_filter {
    height: 99.4%;
    background-color: #515fc9;
    position: absolute;
    width: 100%;
    top: 0;
    margin: 0;
    padding: 0;
    opacity: 0.5;
}

/* Text container */

@media screen and (min-width: 992px) {
    #home_header_section_accexible_logo {
        height: 15vh;
        margin-bottom: 6vw;
    }
    #home_header_section_text {
        margin-left: 1.5vw;
        color: #f8f8ff;
        font-family: "Montserrat", sans-serif;
        font-size: 1.925vw;
    }
}

@media screen and (max-width: 992px) {
    #home_header_section_accexible_logo {
        height: 17.5vw;
        margin-bottom: 4vw;
    }
    #home_header_section_text {
        margin-left: 3.5vw;
        color: #f8f8ff;
        font-family: "Montserrat", sans-serif;
        font-size: 2.725vw;
    }
}

#home_header_section_text_container {
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    margin: 0;
}

#home_header_section_brand_container {
    height: 60%;
}

/* Schedule demo button */

@media screen and (min-width: 992px) {
    #home_header_section_schedule_demo_button_container {
        height: 40%;
        padding-bottom: 7.5vw;
    }
    #home_header_section_schedule_demo_button {
        font-family: "Montserrat", sans-serif;
        font-size: 2.525vw;
        font-weight: 900;
        color: #f8f8ff;
        background-color: transparent;
        padding: 0.825vw 2.25vw 0.825vw 2.25vw;
        border: 0.325vw solid #f8f8ff;
        border-radius: 0vw;
    }
    #home_header_section_schedule_demo_button:hover {
        font-family: "Montserrat", sans-serif;
        font-size: 2.525vw;
        font-weight: 900;
        color: #1d334a;
        background-color: transparent;
        padding: 0.825vw 2.25vw 0.825vw 2.25vw;
        border: 0.325vw solid #1d334a;
        border-radius: 0vw;
    }
}

@media screen and (max-width: 992px) {
    #home_header_section_schedule_demo_button_container {
        height: 40%;
        padding-bottom: 1vw;
    }
    #home_header_section_schedule_demo_button {
        font-family: "Montserrat", sans-serif;
        font-size: 3.75vw;
        font-weight: 900;
        color: #f8f8ff;
        background-color: transparent;
        padding: 1vw 2.5vw 1vw 2.5vw;
        border: 0.415vw solid #f8f8ff;
        border-radius: 0vw;
    }
    #home_header_section_schedule_demo_button:hover {
        font-family: "Montserrat", sans-serif;
        font-size: 3.75vw;
        font-weight: 900;
        color: #1d334a;
        background-color: transparent;
        padding: 1vw 2.5vw 1vw 2.5vw;
        border: 0.415vw solid #1d334a;
        border-radius: 0vw;
    }
}

/* Schedule demo modal view */

#home_header_schedule_demo_modal_view_explanation {
    margin-top: 1.25rem;
    margin-bottom: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

#home_header_schedule_demo_modal_view_request_button {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #515fc9;
    color: #f8f8ff;
    border-color: transparent;
    border-radius: 1.5rem;
    font-size: 1rem;
}

#home_header_schedule_demo_modal_view_close_button {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #f8f8ff;
    color: #515fc9;
    border-color: transparent;
    border-radius: 1.5rem;
    font-size: 1rem;
}