/* TARGETS STYLES */

/* Container */

#home_targets_section_container {
    overflow: hidden;
    background-size: contain;
    background-position: center;
    background: linear-gradient(rgba(255, 255, 255, .125), rgba(255, 255, 255, .125)), url('../../../../../resources/images/background-image/BackgroundImage.jpg');
}

#home_targets_section_container {
    padding-top: 2.5vw;
}

/* Section header */

@media screen and (min-width: 992px) {
    #home_targets_section_header_container {
        margin-bottom: 1.75vw;
    }
    #home_targets_section_container_header {
        position: relative;
        padding-left: 1.525vw;
        margin-left: 1vw;
        margin-right: 1vw;
        font-family: 'Montserrat', sans-serif;
        font-size: 4vw;
        font-weight: 600;
        color: #f8f8ff;
        text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
    }
    #home_targets_section_container_header:after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        bottom: 0;
        width: 0.325vw;
        background: #f8f8ff;
        border-radius: 1vw;
        box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
    }
}

@media screen and (max-width: 992px) {
    #home_targets_section_header_container {
        margin-bottom: 2.25vw;
    }
    #home_targets_section_container_header {
        position: relative;
        padding-left: 2.625vw;
        margin-left: 1vw;
        margin-right: 1vw;
        font-family: 'Montserrat', sans-serif;
        font-size: 4.75vw;
        font-weight: 600;
        color: #f8f8ff;
        text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
    }
    #home_targets_section_container_header:after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        bottom: 0;
        width: 0.625vw;
        background: #f8f8ff;
        border-radius: 1rem;
        box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
    }
}

/* Panels */

@media screen and (min-width: 992px) {
    /* Left panel */
    .home_targets_section_panel_container_left {
        margin-bottom: 4rem;
    }
    .home_targets_section_panel_container_left>article {
        position: relative;
        padding-right: 3.75rem;
        height: 100%;
        margin-bottom: 2.25rem;
        background-color: #f8f8ff;
        border-radius: 10rem;
    }
    .home_targets_section_panel_container_icon_container_left {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 50%;
        background-color: #DCE4FF;
        z-index: 1000;
    }
    .home_targets_section_panel_container_icon_container_left>div {
        width: 100%;
        height: 100%;
    }
    .home_targets_section_panel_container_icon_container_left>div>svg {
        color: #767EB4;
        padding: 1.75vw;
        font-size: 10vw;
    }
    .home_targets_section_panel_container_header_left {
        font-family: "Montserrat", sans-serif;
        font-size: 2.325vw;
        color: #6897c1;
        line-height: 3vw;
        padding-top: 1.5vw;
        padding-bottom: 1vw;
        border-bottom: 2px solid #1d334a;
    }
    .home_targets_section_panel_container_text_left {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.25vw;
        color: #428bca;
        text-align: justify;
        line-height: 2vw;
        margin-right: 2vw;
        padding-top: 0.75rem;
        padding-bottom: 1.5rem;
    }
    /* Right panel */
    .home_targets_section_panel_container_right {
        margin-bottom: 4rem;
    }
    .home_targets_section_panel_container_right>article {
        position: relative;
        padding-left: 3.75rem;
        height: 100%;
        margin-bottom: 2.25rem;
        background-color: #f8f8ff;
        border-radius: 10rem;
    }
    .home_targets_section_panel_container_icon_container_right {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 50%;
        background-color: #DCE4FF;
        z-index: 1000;
    }
    .home_targets_section_panel_container_icon_container_right>div {
        width: 100%;
        height: 100%;
    }
    .home_targets_section_panel_container_icon_container_right>div>svg {
        color: #767EB4;
        padding: 1.75vw;
        font-size: 10vw;
    }
    .home_targets_section_panel_container_header_right {
        font-family: "Montserrat", sans-serif;
        font-size: 2.325vw;
        color: #6897c1;
        line-height: 3vw;
        padding-top: 1.5vw;
        padding-bottom: 1vw;
        border-bottom: 2px solid #1d334a;
    }
    .home_targets_section_panel_container_text_right {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.25vw;
        color: #428bca;
        text-align: justify;
        line-height: 2vw;
        margin-left: 2rem;
        padding-top: 0.75rem;
        padding-bottom: 1.5rem;
    }
}

/* Small screen styles */

.home_targets_section_panel_container_small {
    margin-bottom: 3.25vw;
    margin-left: 1vw;
    margin-right: 1vw;
    padding-top: 2.5vw;
    background-color: #f8f8ff;
    border-radius: 2.5vw;
}

.home_targets_section_panel_container_icon_container_small {
    background-color: #DCE4FF;
    border-radius: 50%;
}

.home_targets_section_panel_container_icon_container_small>div>svg {
    color: #767EB4;
    font-size: 22.5vw;
    padding: 4.5vw;
}

.home_targets_section_panel_container_header_small {
    margin-top: 3.5vw;
    font-family: "Montserrat", sans-serif;
    font-size: 3.75vw;
    color: #6897c1;
    line-height: 4.5vw;
    padding-top: 1.5vw;
    padding-bottom: 1.75vw;
    border-bottom: 0.1vw solid #1d334a;
}

.home_targets_section_panel_container_text_small {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 2.925vw;
    color: #428bca;
    text-align: justify;
    line-height: 3.75vw;
    padding-top: 0.75vw;
    padding-bottom: 2.25vw;
}