/* TEST STEP FINISH VIEW STYLES */

@media screen and (min-width: 992px) {
  #test_battery_step_finish_container {
    display: flex;
    flex-flow: column;
    height: 100%;
    flex: auto;
  }
  #test_battery_step_finish_text_container {
    flex: auto;
  }
  #test_battery_step_finish_text_container > div {
    display: flex;
    flex-flow: column;
    height: 100%;
    flex: auto;
    padding: 0;
  }
  #test_battery_step_finish_explanation_text_container {
    flex: auto;
  }
  .test_battery_step_finish_participation_thank {
    width: 100%;
    margin: 0;
    height: 33.33333%;
    font-size: 2.5vw;
    color: rgba(0, 0, 0, 0.9);
    text-align: center;
  }
  .test_battery_step_finish_finalised {
    width: 100%;
    margin: 0;
    height: 33.33333%;
    font-size: 3.25vw;
    color: green;
    text-align: center;
  }
  .test_battery_step_finish_information {
    width: 100%;
    margin: 0;
    height: 33.33333%;
    font-size: 2.5vw;
    color: rgba(0, 0, 0, 0.9);
    text-align: center;
  }
}

@media screen and (max-width: 992px) {
  #test_battery_step_finish_container {
    display: flex;
    flex-flow: column;
    height: 100%;
    flex: auto;
  }
  #test_battery_step_finish_text_container {
    flex: auto;
  }
  #test_battery_step_finish_text_container > div {
    display: flex;
    flex-flow: column;
    height: 100%;
    flex: auto;
    padding: 0;
  }
  #test_battery_step_finish_explanation_text_container {
    flex: auto;
  }
  .test_battery_step_finish_participation_thank {
    width: 100%;
    margin: 0;
    height: 33.33333%;
    font-size: 6.25vw;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
  }
  .test_battery_step_finish_finalised {
    width: 100%;
    margin: 0;
    height: 33.33333%;
    font-size: 8vw;
    color: green;
    text-align: center;
  }
  .test_battery_step_finish_information {
    width: 100%;
    margin: 0;
    height: 33.33333%;
    font-size: 6.25vw;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
  }
}
