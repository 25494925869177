/* ABOUT US STYLES */

/* Container */

#home_about_us_section_row {
  background-color: rgba(121, 131, 209, 1);
  border-top: 0.5rvw solid #5b79be;
}

#home_section_about_us_text_container {
  margin-top: 3.5vw;
  margin-bottom: 6vw;
}

/* Section header */

@media screen and (min-width: 992px) {
  #home_about_us_section_header_container {
    margin-bottom: 1.75vw;
  }
  #home_about_us_section_container_header {
    position: relative;
    padding-left: 1.525vw;
    margin-left: 1vw;
    margin-right: 1vw;
    font-family: "Montserrat", sans-serif;
    font-size: 4vw;
    font-weight: 600;
    color: #f8f8ff;
    text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  }
  #home_about_us_section_container_header:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    bottom: 0;
    width: 0.325vw;
    background: #f8f8ff;
    border-radius: 1vw;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  }
}

@media screen and (max-width: 992px) {
  #home_about_us_section_header_container {
    margin-bottom: 2.25vw;
  }
  #home_about_us_section_container_header {
    position: relative;
    padding-left: 2.625vw;
    margin-left: 1vw;
    margin-right: 1vw;
    font-family: "Montserrat", sans-serif;
    font-size: 4.75vw;
    font-weight: 600;
    color: #f8f8ff;
    text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  }
  #home_about_us_section_container_header:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    bottom: 0;
    width: 0.625vw;
    background: #f8f8ff;
    border-radius: 1rem;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  }
}

/* Text container */

@media screen and (min-width: 992px) {
  #home_about_us_section_text_container {
    border-left: 0.325vw solid #e1e5f4;
    margin-left: 1vw;
    margin-right: 1vw;
    padding-left: 1vw;
    padding-right: 1vw;
    background-color: #3d4da2;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12), 0 16px 16px rgba(0, 0, 0, 0.12);
  }
  .home_about_us_section_container {
    margin-top: 2.25vw;
    margin-bottom: 2.25vw;
  }
  .home_about_us_section_explanation {
    margin-left: 1vw;
    margin-right: 1vw;
    font-family: "Montserrat", sans-serif;
    text-align: justify;
    color: #e1e5f4;
    font-size: 1.525vw;
    line-height: 2.5vw;
  }
}

@media screen and (max-width: 992px) {
  #home_about_us_section_text_container {
    border-left: 0.625vw solid #e1e5f4;
    margin-left: 1vw;
    margin-right: 1vw;
    background-color: #4d60c0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12), 0 16px 16px rgba(0, 0, 0, 0.12);
  }
  .home_about_us_section_container {
    margin-top: 1.25vw;
    margin-bottom: 1.25vw;
  }
  .home_about_us_section_explanation {
    margin-top: 1.25vw;
    padding-left: 2.25vw;
    padding-right: 2.25vw;
    font-family: "Montserrat", sans-serif;
    font-size: 3vw;
    color: #f8f8ff;
    line-height: 4.25vw;
    text-align: justify;
  }
}

/* Section header */

@media screen and (min-width: 992px) {
  .home_about_us_section_header_container {
    display: inline-block;
  }
  .home_about_us_section_header_icon_container {
    padding-right: 2.25vw;
    color: #e1e5f4;
    font-size: 4vw;
  }
  .home_about_us_section_header_icon_container > svg {
    vertical-align: middle;
  }
  .home_about_us_section_header_text {
    font-family: "Montserrat", sans-serif;
    font-size: 3.25vw;
    color: #e1e5f4;
  }
}

@media screen and (max-width: 992px) {
  .home_about_us_section_header_container {
    display: inline-block;
  }
  .home_about_us_section_header_icon_container {
    padding-right: 2.75vw;
    color: #e1e5f4;
    font-size: 6.125vw;
  }
  .home_about_us_section_header_icon_container > svg {
    vertical-align: middle;
  }
  .home_about_us_section_header_text {
    font-family: "Montserrat", sans-serif;
    font-size: 4.25vw;
    color: #e1e5f4;
  }
}

/* Profile */

/* Container */

.rah-static--height-specific > div {
  height: 100%;
}

@media screen and (min-width: 992px) {
  .home_about_us_section_profile_container {
    flex: 1;
    width: 100%;
    position: relative;
    margin-top: 2.5vw;
    width: 100%;
    padding: 1vw;
    background-color: #c6d0ff;
    border: 0.15vw solid #e1e5f4;
    border-radius: 0.5vw;
  }
  .home_about_us_section_profile_visible {
    opacity: 1;
    height: 100%;
    transition: height 0.5s, opacity 0.5s 0.5s;
  }
  .home_about_us_section_profile_not_visible {
    opacity: 0;
    height: 100%;
    transition: height 0.5s 0.5s, opacity 0.5s;
  }
}

@media screen and (max-width: 992px) {
  .home_about_us_section_profile {
    margin-right: 1vw;
    margin-left: 1vw;
  }
  .home_about_us_section_profile_container {
    flex: 1;
    width: 100%;
    position: relative;
    margin-top: 2.5vw;
    width: 100%;
    padding: 1vw;
    background-color: #c6d0ff;
    border: 0.15vw solid #e1e5f4;
    border-radius: 0.5vw;
  }
  .home_about_us_section_profile_visible {
    opacity: 1;
    height: 100%;
    margin: 0;
    transition: height 0.5s, opacity 0.5s 0.5s;
  }
  .home_about_us_section_profile_not_visible {
    opacity: 0;
    margin: 0;
    height: 100%;
    transition: height 0.5s 0.5s, opacity 0.5s;
  }
}

/* Profile name and position */

@media screen and (min-width: 992px) {
  .home_about_us_section_profile_name {
    font-family: "Montserrat", sans-serif;
    font-size: 1.325vw;
    line-height: 2.75vw;
    color: #031121;
  }
  .home_about_us_section_profile_position {
    padding-top: 0.325vw;
    font-family: "Montserrat", sans-serif;
    font-size: 1.105vw;
    color: #697787;
  }
  .home_about_us_section_profile_find_out_more_container {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding-right: 0.5vw;
  }
  .home_about_us_section_profile_find_out_more_text {
    font-size: 0.625vw;
    color: rgba(0, 0, 0, 0.5);
  }
}

@media screen and (max-width: 992px) {
  .home_about_us_section_profile_name {
    margin-top: 1.125vw;
    font-family: "Montserrat", sans-serif;
    font-size: 2.925vw;
    line-height: 3.25vw;
    color: #031121;
  }
  .home_about_us_section_profile_position {
    padding-top: 0.325vw;
    font-family: "Montserrat", sans-serif;
    font-size: 2.25vw;
    color: #697787;
  }
  .home_about_us_section_profile_find_out_more_container {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding-right: 0.5vw;
  }
  .home_about_us_section_profile_find_out_more_text {
    font-size: 0.625vw;
    color: rgba(0, 0, 0, 0.5);
  }
}

/* Profile photos */

@media screen and (min-width: 992px) {
  /* Team */
  .home_about_us_section_profile_carla_zaldua_aguirre_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/carla_zaldua_aguirre.jpeg");
    width: 7.5vw;
    height: 7.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_javier_zaldua_aguirre_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/javier_zaldua_aguirre.png");
    width: 7.5vw;
    height: 7.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_tomas_rojo_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/tomas_rojo.png");
    width: 7.5vw;
    height: 7.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_victoria_de_la_orden_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/victoria_de_la_orden.jpeg");
    width: 7.5vw;
    height: 7.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_ania_zuazua_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/ania_zuazua.jpg");
    width: 7.5vw;
    height: 7.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_xabier_hernando_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/xabier_hernando.png");
    width: 7.5vw;
    height: 7.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_peru_gabirondo_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/peru_gabirondo.jpg");
    width: 7.5vw;
    height: 7.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_patricia_dias_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/patricia_dias.jpeg");
    width: 7.5vw;
    height: 7.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_iker_honorato_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/iker_honorato.jpg");
    width: 7.5vw;
    height: 7.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_dario_gallego_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/dario_gallego.jpeg");
    width: 7.5vw;
    height: 7.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }

  /* Advisors */
  .home_about_us_section_profile_javier_jimenez_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/javier_jimenez.png");
    width: 7.5vw;
    height: 7.5vw;
    background-size: contain;
    background-position: center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_pablo_de_la_guardia_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/pablo_de_la_guardia.png");
    width: 7.5vw;
    height: 7.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_donald_a_davidoff_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/donald_a_davidoff.jpg");
    width: 7.5vw;
    height: 7.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_pedro_montejo_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/pedro_montejo.jpg");
    width: 7.5vw;
    height: 7.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
}

@media screen and (max-width: 992px) {
  /* Team */
  .home_about_us_section_profile_carla_zaldua_aguirre_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/carla_zaldua_aguirre.jpeg");
    width: 12.5vw;
    height: 12.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_javier_zaldua_aguirre_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/javier_zaldua_aguirre.png");
    width: 12.5vw;
    height: 12.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_tomas_rojo_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/tomas_rojo.png");
    width: 12.5vw;
    height: 12.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_victoria_de_la_orden_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/victoria_de_la_orden.jpeg");
    width: 12.5vw;
    height: 12.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_ania_zuazua_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/ania_zuazua.jpg");
    width: 12.5vw;
    height: 12.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_xabier_hernando_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/xabier_hernando.png");
    width: 12.5vw;
    height: 12.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_peru_gabirondo_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/peru_gabirondo.jpg");
    width: 12.5vw;
    height: 12.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_patricia_dias_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/patricia_dias.jpeg");
    width: 12.5vw;
    height: 12.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_iker_honorato_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/iker_honorato.jpg");
    width: 12.5vw;
    height: 12.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_dario_gallego_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/dario_gallego.jpeg");
    width: 12.5vw;
    height: 12.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }

  /* Advisors */
  .home_about_us_section_profile_javier_jimenez_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/javier_jimenez.png");
    width: 12.5vw;
    height: 12.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_pablo_de_la_guardia_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/pablo_de_la_guardia.png");
    width: 12.5vw;
    height: 12.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_donald_a_davidoff_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/donald_a_davidoff.jpg");
    width: 12.5vw;
    height: 12.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
  .home_about_us_section_profile_pedro_montejo_photo_container {
    background: linear-gradient(0deg, rgba(92, 146, 255, 0.31), rgba(92, 146, 255, 0.31)), url("../../../../../resources/images/home/about_us/team/pedro_montejo.jpg");
    width: 12.5vw;
    height: 12.5vw;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }
}

/* Profile details */

@media screen and (min-width: 992px) {
  .home_about_us_section_profile_details_header_container {
    padding-bottom: 0.75vw;
    margin-right: 0.3vw;
    border-bottom: 0.1175vw solid #031121;
  }
  .home_about_us_section_profile_details_header_name {
    font-family: "Montserrat", sans-serif;
    font-size: 1.15vw;
    color: #031121;
  }
  .home_about_us_section_profile_details_header_position {
    font-family: "Montserrat", sans-serif;
    font-size: 1.15vw;
    color: #697787;
  }
  .home_about_us_section_profile_details_description {
    font-family: "Montserrat", sans-serif;
    margin-right: 0.45vw;
    font-size: 0.925vw;
    line-height: 1.525vw;
    color: #6b79e3;
    text-align: justify;
    padding-top: 1vw;
  }
}

@media screen and (max-width: 992px) {
  .home_about_us_section_profile_details_header_container {
    padding-bottom: 0.75vw;
    margin-right: 0.3vw;
    border-bottom: 0.1175vw solid #031121;
  }
  .home_about_us_section_profile_details_header_name {
    font-family: "Montserrat", sans-serif;
    font-size: 3.125vw;
    color: #031121;
  }
  .home_about_us_section_profile_details_header_position {
    font-family: "Montserrat", sans-serif;
    font-size: 2.565vw;
    color: #697787;
  }
  .home_about_us_section_profile_details_description {
    font-family: "Montserrat", sans-serif;
    margin-right: 0.45vw;
    font-size: 2.625vw;
    line-height: 3.5vw;
    color: #6b79e3;
    text-align: justify;
    padding-top: 2vw;
  }
}

/* Partnerships */

@media screen and (min-width: 992px) {
  .home_about_us_section_partnership_container {
    margin-top: 2.675vw;
    width: 100%;
    padding: 1.125vw;
    background-color: #e1e5f4;
    border: 0.175vw solid #306a89;
  }
  .home_about_us_section_partnership_header_container {
    padding-bottom: 0.75vw;
    border-bottom: 0.115vw solid #031121;
  }
  .home_about_us_section_partnership_header {
    font-family: "Montserrat", sans-serif;
    font-size: 1.375vw;
    color: #031121;
  }
  .home_about_us_section_partnership_image_container {
    width: 100%;
  }
  .home_about_us_section_partnership_image_container > img {
    display: block;
    height: 7vw;
    margin-top: 2vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 992px) {
  .home_about_us_section_partnership_container {
    margin-top: 2.675vw;
    margin-right: 1vw;
    margin-left: 1vw;
    width: 100%;
    padding: 1.325vw;
    background-color: #e1e5f4;
    border: 0.15vw solid #306a89;
  }
  .home_about_us_section_partnership_header_container {
    padding-bottom: 0.75vw;
    border-bottom: 0.115vw solid #031121;
  }
  .home_about_us_section_partnership_header {
    font-family: "Montserrat", sans-serif;
    font-size: 2.5vw;
    color: #031121;
  }
  .home_about_us_section_partnership_image_container {
    width: 100%;
  }
  .home_about_us_section_partnership_image_container > img {
    display: block;
    height: 12.5vw;
    margin-top: 2vw;
    margin-left: auto;
    margin-right: auto;
  }
}
