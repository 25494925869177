/* TEST BATTERY EXPLANATION STYLES */

/* Container */

#test_battery_explanation_container {
  display: flex;
  flex-flow: column;
  height: 100%;
  flex: auto;
}

/* Header */

@media screen and (min-width: 992px) {
  #test_battery_explanation_header {
    width: 100%;
    padding-bottom: 1vw;
    border-bottom: 0.1vw solid #515fc9;
  }
  #test_battery_explanation_header > h1 {
    font-size: 3vw;
    font-weight: 500;
    color: #515fc9;
  }
}

@media screen and (max-width: 992px) {
  #test_battery_explanation_header {
    width: 100%;
    padding-bottom: 1.75vw;
    border-bottom: 0.05vw solid #515fc9;
  }
  #test_battery_explanation_header > h1 {
    font-size: 4vw;
    font-weight: 500;
    color: #515fc9;
  }
}

/* Explanation container */

@media screen and (min-width: 992px) {
  #test_battery_explanation_text_container {
    flex: auto;
  }
  .test_battery_explanation_text {
    color: rgba(0, 0, 0, 0.9);
    font-size: 2.2vw;
  }
  .test_battery_subexplanation_text {
    font-size: 2vw;
    padding-left: 2vw;
  }
  .test_battery_subexplanation_text_2 {
    font-size: 1.5vw;
    font-weight: bold;
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  #test_battery_explanation_text_container {
    flex: auto;
  }
  .test_battery_explanation_text {
    color: rgba(0, 0, 0, 0.9);
    font-size: 3.5vw;
  }
  .test_battery_subexplanation_text {
    font-size: 2.5vw;
    padding-left: 2vw;
    margin-bottom: 7vh;
  }
  .test_battery_subexplanation_text_2 {
    font-size: 2.3vw;
    font-weight: bold;
    line-height: 1.3em;
    text-align: center;
    width: 100%;
  }
}

/* Button */

@media screen and (min-width: 992px) {
  #test_battery_explanation_start_button_container {
    width: 100%;
    padding-top: 1vw;
    border-top: 0.1vw solid #515fc9;
  }
  #test_battery_explanation_start_button {
    font-size: 2.625vw;
    font-weight: 500;
    background-color: #515fc9;
    color: #f8f8ff;
    padding: 1vh 2.625vw 1vh 2.625vw;
    border-radius: 5vw;
  }
  #test_battery_explanation_start_button:hover {
    background-color: #f8f8ff;
    color: #515fc9;
  }
}

@media screen and (max-width: 992px) {
  #test_battery_explanation_start_button_container {
    width: 100%;
    padding-top: 1.75vw;
    border-top: 0.1vw solid #515fc9;
  }
  #test_battery_explanation_start_button {
    font-size: 4.75vw;
    font-weight: 500;
    background-color: #515fc9;
    color: #f8f8ff;
    padding: 1.025vh 5.25vw 1.025vh 5.25vw;
    border-radius: 10vw;
  }
  #test_battery_explanation_start_button:hover {
    background-color: #f8f8ff;
    color: #515fc9;
  }
}
