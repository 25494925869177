/* SECTION ANIMATION STYLES */

.widget_section_animation_fade_in {
    width: 100%;
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
}

.widget_section_animation_fade_in.widget_section_animation_is_visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}