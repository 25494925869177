/* Sign in formulary styles */

/* Background image */

#sign_in_page_background_image {
    min-height: 100%;
    min-width: 1024px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.85;
}

/* Page container */

#sign_in_page {
    display: flex;
    flex-flow: column;
    height: 100%;
}

#sign_in_page_container {
    flex-grow: 1;
}

/* Sign in form */

@media screen and (min-width: 992px) {
    #sign_in_form_container {
        margin-bottom: 4.5vh;
        padding: 1vw 1vw 1vw 1vw;
        background-color: #f8f8ff;
        border-radius: 1vw;
    }
    #sign_in_form_log_in_button {
        margin-top: 1.25vw;
        width: 100%;
        font-weight: 500;
        background-color: #515fc9;
        color: #f8f8ff;
        font-size: 1.5vw;
    }
}

@media screen and (max-width: 992px) {
    #sign_in_form_container {
        padding: 4.5vw 5vw 2.5vw 4.5vw;
        background-color: #f8f8ff;
        border-radius: 1vw;
    }
    #sign_in_form_log_in_button {
        margin-top: 1vw;
        width: 100%;
        font-weight: 500;
        background-color: #515fc9;
        color: #f8f8ff;
        font-size: 4.5vw;
    }
}

/*
#create_account {
    margin-top: 0.2rem;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, .5);
}

#create_account_button {
    font-size: 0.875rem;
    padding: 0;
    padding-top: inherit;
    margin-left: 0.3rem;
    border: none;
    color: rgba(0, 0, 0, .5);
}

#create_account_button:hover {
    color: #515fc9;
}

-- New user account styles

#newUser_form_button_create {
    width: 12.50rem;
    margin-bottom: 1rem;
    background-color: #515fc9;
    color: #f8f8ff;
    border-color: transparent;
    border-radius: 1.5rem;
    font-size: 1.15rem;
}

#newUser_form_button_close {
    width: 12.50rem;
    margin-bottom: 1rem;
    background-color: #f8f8ff;
    color: #515fc9;
    border-color: transparent;
    border-radius: 1.5rem;
    font-size: 1.15rem;
}

*/