/* APPLICATION STYLES */

/* Container */

#home_application_section_row {
    background-color: rgba(97, 110, 200, 1);
    border-bottom: 0.575vw solid #8EA0D5;
}

#home_application_section_container {
    margin-top: 3.5vw;
    margin-bottom: 3.5vw;
}

/* Section header */

@media screen and (min-width: 992px) {
    #home_application_section_header_container {
        margin-bottom: 1.75vw;
    }
    #home_application_section_container_header {
        position: relative;
        padding-left: 1.525vw;
        margin-left: 1vw;
        margin-right: 1vw;
        font-family: 'Montserrat', sans-serif;
        font-size: 4vw;
        font-weight: 600;
        color: #f8f8ff;
        text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
    }
    #home_application_section_container_header:after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        bottom: 0;
        width: 0.325vw;
        background: #f8f8ff;
        border-radius: 1vw;
        box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
    }
}

@media screen and (max-width: 992px) {
    #home_application_section_header_container {
        margin-bottom: 2.25vw;
    }
    #home_application_section_container_header {
        position: relative;
        padding-left: 2.625vw;
        margin-left: 1vw;
        margin-right: 1vw;
        font-family: 'Montserrat', sans-serif;
        font-size: 4.75vw;
        font-weight: 600;
        color: #f8f8ff;
        text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
    }
    #home_application_section_container_header:after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        bottom: 0;
        width: 0.625vw;
        background: #f8f8ff;
        border-radius: 1rem;
        box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
    }
}

/* Text container */

@media screen and (min-width: 992px) {
    #home_application_section_text_container {
        border-left: 0.325vw solid #e1e5f4;
        margin-left: 1vw;
        margin-right: 1vw;
        padding-left: 1vw;
        padding-right: 1vw;
        background-color: #4d60c0;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12), 0 16px 16px rgba(0, 0, 0, 0.12);
    }
    #home_application_section_explanation {
        margin-top: 2.25vw;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.525vw;
        color: #f8f8ff;
        line-height: 2.5vw;
        text-align: justify;
    }
}

@media screen and (max-width: 992px) {
    #home_application_section_text_container {
        border-left: 0.625vw solid #e1e5f4;
        margin-left: 1vw;
        margin-right: 1vw;
        background-color: #4d60c0;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12), 0 16px 16px rgba(0, 0, 0, 0.12);
    }
    #home_application_section_explanation {
        margin-top: 2.25vw;
        font-family: 'Montserrat', sans-serif;
        font-size: 3vw;
        color: #f8f8ff;
        line-height: 4.25vw;
        text-align: justify;
    }
}

/* Cards */

/* Relation size 1.3461538462*/

@media screen and (min-width: 992px) {
    #home_application_section_card_container {
        margin-top: 3vw;
    }
    .home_application_card {
        margin-bottom: 2.25vw;
        padding-right: 2vw;
        padding-left: 2vw;
    }
    article.home_application_section_card {
        height: 16vw;
        border-radius: 0.25vw;
        color: #f8f8ff;
        box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
        overflow: hidden;
    }
    .home_application_section_card_icon_container {
        width: 100%;
        height: 100%;
    }
    .home_application_section_card_icon_container>svg {
        font-size: 7.5vw;
        color: #D2DCFF;
    }
    .home_application_section_card_hover_text {
        text-align: end;
        margin-top: 0.25vw;
        font-size: 0.625vw;
        color: #32577f;
        opacity: 1;
        transition: opacity 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
    }
    article.home_application_section_card .home_application_section_card_thumb {
        background-color: #ACB6FF;
        width: auto;
        height: 11.8857142853vw;
    }
    article.home_application_section_card .home_application_section_card_information {
        width: auto;
        height: 16vw;
        position: relative;
        padding: 1vw 2vw;
        background: #D2DCFF;
        transition: 0.4s 0.15s cubic-bezier(0.17, 0.67, 0.5, 1.03);
    }
    article.home_application_section_card .home_application_section_card_information .home_application_section_card_header {
        position: relative;
        margin: 0;
        color: #031121;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.325vw;
        text-transform: uppercase;
        text-shadow: 0 0 0px #32577f;
    }
    article.home_application_section_card .home_application_section_card_information .home_application_section_card_text {
        position: absolute;
        left: 0;
        left: 0;
        bottom: 0;
        margin: 1.25vw 0;
        padding: 1vw 2vw;
        color: #7f7f7f;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.125vw;
        cursor: pointer;
        opacity: 0;
        transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
    }
    article.home_application_section_card:hover .home_application_section_card_information {
        transform: translateY(-11.8857142853vw);
    }
    article.home_application_section_card:hover .home_application_section_card_information .home_application_section_card_text {
        opacity: 1;
    }
    article.home_application_section_card:hover .home_application_section_card_hover_text {
        opacity: 0;
        transition: opacity 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
    }
}

@media screen and (max-width: 992px) {
    #home_application_section_card_container {
        margin-top: 3vw;
    }
    .home_application_card {
        margin-bottom: 4vw;
        padding-right: 4vw;
        padding-left: 4vw;
    }
    article.home_application_section_card {
        height: 30vw;
        border-radius: 0.25vw;
        color: #f8f8ff;
        box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
        overflow: hidden;
    }
    .home_application_section_card_icon_container {
        width: 100%;
        height: 100%;
    }
    .home_application_section_card_icon_container>svg {
        font-size: 15.25vw;
        color: #D2DCFF;
    }
    .home_application_section_card_hover_text {
        text-align: end;
        margin-top: 0.25vw;
        font-size: 0.625vw;
        color: #32577f;
        opacity: 1;
        transition: opacity 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
    }
    article.home_application_section_card .home_application_section_card_thumb {
        background-color: #ACB6FF;
        width: auto;
        height: 22.285714285vw;
    }
    article.home_application_section_card .home_application_section_card_information {
        width: auto;
        height: 30vw;
        position: relative;
        padding: 1vw 2vw;
        background: #D2DCFF;
        transition: 0.4s 0.15s cubic-bezier(0.17, 0.67, 0.5, 1.03);
    }
    article.home_application_section_card .home_application_section_card_information .home_application_section_card_header {
        position: relative;
        margin: 0;
        margin-top: 0.75vw;
        color: #031121;
        font-family: 'Montserrat', sans-serif;
        font-size: 3.625vw;
        text-transform: uppercase;
        text-shadow: 0 0 0px #32577f;
    }
    article.home_application_section_card .home_application_section_card_information .home_application_section_card_text {
        position: absolute;
        left: 0;
        left: 0;
        bottom: 0;
        margin: 1.25vw 0;
        padding: 1vw 2vw;
        color: #7f7f7f;
        font-family: 'Montserrat', sans-serif;
        font-size: 2.75vw;
        line-height: 4.25vw;
        cursor: pointer;
        opacity: 0;
        transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
    }
    article.home_application_section_card:hover .home_application_section_card_information {
        transform: translateY(-22.285714285vw);
    }
    article.home_application_section_card:hover .home_application_section_card_information .home_application_section_card_text {
        opacity: 1;
    }
    article.home_application_section_card:hover .home_application_section_card_hover_text {
        opacity: 0;
        transition: opacity 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
    }
}

/* Video */

#home_application_section_video_container {
    margin-top: 3vw;
}

#home_application_section_video {
    margin-bottom: 1.75vw;
    width: 100%;
    object-fit: fill;
}

/* Schedule demo button */
@media screen and (min-width: 992px) {
    #home_application_section_schedule_demo_button {
        margin-top: 1vw;
        margin-bottom: 3vw;
        font-family: "Montserrat", sans-serif;
        font-size: 2.525vw;
        font-weight: 900;
        color: #f8f8ff;
        background-color: transparent;
        padding: 0.825vw 2.25vw 0.825vw 2.25vw;
        border: 0.325vw solid #f8f8ff;
        border-radius: 0vw;
    }
    
    #home_application_section_schedule_demo_button:hover {
        margin-bottom: 3vw;
        font-family: "Montserrat", sans-serif;
        font-size: 2.525vw;
        font-weight: 900;
        color: #1d334a;
        background-color: #f8f8ff;
        padding: 0.825vw 2.25vw 0.825vw 2.25vw;
        border: 0.325vw solid #1d334a;
        border-radius: 0vw;
    }
}

@media screen and (max-width: 992px) {
    #home_application_section_schedule_demo_button {
        margin-top: 1vw;
        margin-bottom: 4vw;
        font-family: "Montserrat", sans-serif;
        font-size: 3.75vw;
        font-weight: 900;
        color: #f8f8ff;
        background-color: transparent;
        padding: 1vw 2.5vw 1vw 2.5vw;
        border: 0.415vw solid #f8f8ff;
        border-radius: 0vw;
    }
    
    #home_application_section_schedule_demo_button:hover {
        margin-top: 1vw;
        margin-bottom: 4vw;
        font-family: "Montserrat", sans-serif;
        font-size: 3.75vw;
        font-weight: 900;
        color: #1d334a;
        background-color: #f8f8ff;
        padding: 1vw 2.5vw 1vw 2.5vw;
        border: 0.415vw solid #1d334a;
        border-radius: 0vw;
    }
}