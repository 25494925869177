/* TEST BATTERY DETAILS (PATIENT VERSION) STYLES */

/* Container */

#profile_page_patient_version_navigation_profile_details_container {
    display: flex;
    flex-flow: column;
    flex: auto;
    height: 100%;
    width: 100%;
    outline: 0.175vw solid #515fc9;
}

/* Header */

#profile_patient_test_battery_details_header_container {
    font-family: 'Montserrat', sans-serif;
    padding-top: 0.425vw;
    padding-bottom: 0.425vw;
    font-size: 2vw;
    font-weight: 600;
    color: #515fc9;
    border-bottom: 0.05vw solid #515fc9;
}

/* Test battery information */

#profile_patient_test_battery_details_information_container {
    padding-top: 0.5vw;
    padding-bottom: 0.75vw;
    border-bottom: 0.05vw solid #515fc9;
}

#profile_patient_test_battery_details_information_container > div:first-child,
#profile_patient_test_battery_details_information_container > div:last-child {
    padding-left: 2vw;
}

img[id^="patient_score_image"] {
    max-height: 159px;
    min-height: 132px;
}

#profile_patient_test_battery_details_information_header {
    font-family: 'Montserrat', sans-serif;
    padding-top: 0.25vw;
    padding-bottom: 0.625vw;
    font-size: 1.325vw;
    font-weight: 600;
    color: #515fc9;
}

#profile_patient_test_battery_details_information_table_date {
    font-weight: 500;
    color: #515fc9;
    border-right: 0.001rem #d8d8d8 solid;
    font-size: 1vw;
}

#profile_patient_test_battery_details_information_table_completed {
    font-weight: 500;
    color: #515fc9;
    border-right: 0.001rem #d8d8d8 solid;
    font-size: 1vw;
}

#profile_patient_test_battery_details_information_table_uploaded {
    font-weight: 500;
    color: #515fc9;
    border-right: 0.001rem #d8d8d8 solid;
    font-size: 1vw;
}

#profile_patient_test_battery_details_information_table_result {
    font-weight: 500;
    color: #515fc9;
    border-right: 0.001rem #d8d8d8 solid;
    font-size: 1vw;
}

#profile_patient_test_battery_details_information_table_show_score_button {
    background-color: transparent;
    color: #515fc9;
    padding: 0 1vw 0 1vw;
    border: none;
}

#profile_patient_test_battery_details_information_table_show_score_button:hover {
    background-color: #515fc9;
    color: #d8d8d8;
    padding: 0 1vw 0 1vw;
    border: none;
}

.profile_patient_test_battery_details_score_modal_text {
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
    font-size: 1.15vw;
    color: rgba(0, 0, 0, 0.5);
}

#profile_patient_test_battery_details_score_modal_close_button {
    font-size: 1.5vw;
    font-weight: 500;
    background-color: #f8f8ff;
    color: #515fc9;
    padding: 0.625vh 2.25vw 0.625vh 2.25vw;
    border-radius: 5vw;
    border-color: transparent;
}

#profile_patient_test_battery_details_score_modal_close_button:hover {
    font-size: 1.5vw;
    font-weight: 500;
    background-color: #515fc9;
    color: #f8f8ff;
    padding: 0.625vh 2.25vw 0.625vh 2.25vw;
    border-radius: 5vw;
    border-color: transparent;
}

/* File list container */

#profile_patient_test_battery_details_file_list_container_header {
    font-family: 'Montserrat', sans-serif;
    padding-top: 0.25vw;
    padding-bottom: 0.625vw;
    font-size: 1.325vw;
    font-weight: 600;
    color: #515fc9;
}

#profile_patient_test_battery_details_file_list_container {
    flex: auto;
    height: 100%;
}