/* TEST STEP HEADER STYLES */

@media screen and (min-width: 992px) {
    #test_step_common_view_header_container {
        width: 100%;
        padding-bottom: 1vw;
        border-bottom: 0.1vw solid #515fc9;
    }
    #test_step_common_view_header_container>h1 {
        font-size: 3vw;
        font-weight: 500;
        color: #515fc9;
    }
}

@media screen and (max-width: 992px) {
    #test_step_common_view_header_container {
        width: 100%;
        padding-bottom: 1.75vw;
        border-bottom: 0.05vw solid #515fc9;
    }
    #test_step_common_view_header_container>h1 {
        font-size: 4vw;
        font-weight: 500;
        color: #515fc9;
    }
}