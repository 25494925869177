/* SOUND VISUALIZER STYLES */

@media screen and (min-width: 992px) {
    #widget_sound_visualizer_container {
        box-sizing: border-box;
        width: 100%;
        padding-top: 1vw;
        padding-bottom: 1vw;
        padding-left: 1vw;
        padding-right: 1vw;
        flex: auto;
        height: 100%;
        align-items: center;
        margin: 0;
    }
    .widget_sound_visualizer_sound_bar {
        height: 100%;
        width: 1.0101010101%;
        border: none;
        border-radius: 5vw;
    }
    .widget_sound_visualizer_sound_bar_interstitial {
        height: 100%;
        width: 1.0101010101%;
        background-color: transparent;
        color: transparent;
        border: none;
    }
}

@media screen and (max-width: 992px) {
    #widget_sound_visualizer_container {
        box-sizing: border-box;
        width: 100%;
        padding-top: 15vw;
        padding-bottom: 15vw;
        padding-left: 1vw;
        padding-right: 1vw;
        flex: auto;
        height: 100%;
        align-items: center;
        margin: 0;
    }
    .widget_sound_visualizer_sound_bar {
        height: 100%;
        width: 1.0101010101%;
        border: none;
        border-radius: 5vw;
    }
    .widget_sound_visualizer_sound_bar_interstitial {
        height: 100%;
        width: 1.0101010101%;
        background-color: transparent;
        color: transparent;
        border: none;
    }
}