/*TEST BATTERY LIST (PATIENT VERSION) STYLES */

/* Container */

#profile_page_patient_version_navigation_profile_list_container {
    height: 100%;
    width: 100%;
    padding: 0;
}

#profile_page_patient_version_navigation_profile_list_parent_container {
    display: table;
    height: 100%;
    width: 100%;
    background-color: white;
    outline: 0.175vw solid #515fc9;
}

#profile_page_patient_version_navigation_profile_list_child_container {
    display: table-cell;
    height: 100%;
}

#profile_page_patient_version_navigation_profile_list_subparent_container {
    display: block;
    height: 100%;
    width: 100%;
    display: table;
}

/* List header delete button styles */

#profile_page_patient_version_navigation_profile_list_button_container {
    width: 100%;
    height: 5%;
    display: table-row;
    background-color: #EAF8FF;
    text-align: end;
}

#profile_page_patient_version_navigation_profile_list_button_delete {
    font-size: 2vw;
}

/* Test battery list styles */

#profile_page_patient_version_navigation_profile_list {
    width: 100%;
    height: 95%;
    display: table-row;
}

#profile_page_patient_version_navigation_profile_list_content_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

#profile_page_patient_version_navigation_profile_list_content {
    overflow-y: scroll;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

#profile_page_patient_version_navigation_profile_list_content>ul {
    list-style: none;
    padding: 0;
}

#profile_page_patient_version_navigation_profile_list_content>ul>li {
    font-size: 1.15vw;
    font-family: 'Montserrat', sans-serif;
    padding: 0.5vw 0.75vw 0.5vw 0.75vw;
    border-bottom: 0.01vw solid #515fc9;
}

.profile_page_patient_version_navigation_profile_list_object:hover {
    cursor: pointer;
}