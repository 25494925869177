/* TEST STEP BUTTON STYLES */

@media screen and (min-width: 992px) {
    #test_step_common_view_button_container {
        width: 100%;
        padding-top: 1vw;
        border-top: 0.1vw solid #515fc9;
    }
    #test_step_common_view_button {
        font-size: 2.625vw;
        font-weight: 500;
        background-color: #515fc9;
        color: #f8f8ff;
        padding: 1vh 2.625vw 1vh 2.625vw;
        border-radius: 5vw;
    }
    #test_step_common_view_button:hover {
        background-color: #f8f8ff;
        color: #515fc9;
    }
}

@media screen and (max-width: 992px) {
    #test_step_common_view_button_container {
        width: 100%;
        padding-top: 1.75vw;
        border-top: 0.1vw solid #515fc9;
    }
    #test_step_common_view_button {
        font-size: 4.75vw;
        font-weight: 500;
        background-color: #515fc9;
        color: #f8f8ff;
        padding: 1.025vh 5.25vw 1.025vh 5.25vw;
        border-radius: 10vw;
    }
    #test_step_common_view_button:hover {
        background-color: #f8f8ff;
        color: #515fc9;
    }
}