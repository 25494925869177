/* TEST BATTERY STYLES */

/* Container */

#profile_page {
    display: flex;
    flex-flow: column;
    height: 95vh;
    padding-top: 3vh;
    padding-bottom: 5vh;
}

#profile_page_container {
    display: flex;
    flex-flow: column;
    height: 100%;
    flex-grow: 1;
}

/* Background image */

#profile_background_image {
    min-height: 100%;
    min-width: 1024px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.85;
}

/* Patient version styles */

#profile_page_patient_version_container {
    display: flex;
    flex-flow: column;
    height: 100%;
    flex-grow: 1;
    background-color: #f8f8ff;
    border-radius: 1vw;
    border: 0.1vw solid #515fc9;
}

/* Navigation container */

#profile_page_patient_version_explanation_container {
    padding-right: 1.5vw;
    padding-left: 1.5vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    font-size: 1.025vw;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
    color: rgba(0, 0, 0, 0.5);
}

#profile_page_patient_version_navigation_container {
    flex-grow: 1;
    padding-right: 1.5vw;
    padding-left: 1.5vw;
    margin-bottom: 1.5vw;
}

/* Start new test battery button */

#profile_page_patient_version_start_test_battery_button_container {
    padding-top: 1vw;
    padding-bottom: 1vw;
}

@media screen and (min-width: 992px) {
    #profile_start_test_battery_button {
        padding: 0.525vw 1.75vw 0.525vw 1.75vw;
        background-color: #515fc9;
        font-size: 2vw;
        font-weight: 500;
        color: #f8f8ff;
        border-radius: 5vw;
        border: 0.125vw solid #515fc9;
    }
    #profile_start_test_battery_button:hover {
        padding: 0.525vw 1.75vw 0.525vw 1.75vw;
        background-color: #f8f8ff;
        font-size: 2vw;
        font-weight: 500;
        color: #515fc9;
        border-radius: 5vw;
        border: 0.125vw solid #515fc9;
    }
}

@media screen and (max-width: 992px) {
    #profile_start_test_battery_button {
        padding: 1.125vw 3vw 1.125vw 3vw;
        background-color: #515fc9;
        font-size: 4.5vw;
        font-weight: 500;
        color: #f8f8ff;
        border-radius: 5vw;
        border: 0.125vw solid #515fc9;
    }
    #profile_start_test_battery_button:hover {
        padding: 1.125vw 3vw 1.125vw 3vw;
        background-color: #f8f8ff;
        font-size: 4.5vw;
        font-weight: 500;
        color: #515fc9;
        border-radius: 5vw;
        border: 0.125vw solid #515fc9;
    }
}

/* Permission modal */

#profile_permission_modal_text {
    text-align: justify;
    color: rgba(0, 0, 0, 0.5);
    font-size: 1.125vw;
}

#profile_permission_modal_reload_button_container {
    width: 100%;
}

#profile_permission_modal_reload_button {
    width: 100%;
    font-size: 1.325vw;
    font-weight: 500;
    background-color: #515fc9;
    color: #f8f8ff;
    padding: 0.625vh 2.25vw 0.625vh 2.25vw;
    border-radius: 5vw;
    border-color: transparent;
}

#profile_permission_modal_reload_button:hover {
    width: 100%;
    font-size: 1.325vw;
    font-weight: 500;
    background-color: #f8f8ff;
    color: #515fc9;
    padding: 0.625vh 2.25vw 0.625vh 2.25vw;
    border-radius: 5vw;
    border-color: transparent;
}