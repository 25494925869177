/* TEST STEP EXPLANATION STYLES */

@media screen and (min-width: 992px) {
  #test_step_explanation_view_explanation_container {
    flex: auto;
  }
  #test_step_explanation_view_explanation_container > div {
    display: flex;
    flex-flow: column;
    height: 100%;
    flex: auto;
    padding: 0;
  }
  #test_step_explanation_text_container {
    flex: auto;
  }
  .test_step_explanation_text {
    color: rgba(0, 0, 0, 0.9);
    font-size: 2vw;
    margin-bottom: 3vw;
  }
  .test_step_subexplanation {
    font-size: 1.3vw;
    font-weight: bold;
    line-height: 1.3em;
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  #test_step_explanation_view_explanation_container {
    flex: auto;
  }
  #test_step_explanation_view_explanation_container > div {
    display: flex;
    flex-flow: column;
    height: 100%;
    flex: auto;
    padding: 0;
  }
  #test_step_explanation_text_container {
    flex: auto;
  }
  .test_step_explanation_text {
    color: rgba(0, 0, 0, 0.9);
    font-size: 3vw;
    margin-bottom: 3rem;
  }
  .test_step_subexplanation {
    font-size: 2.25vw;
    font-weight: bold;
    line-height: 1.3em;
    text-align: center;
    width: 100%;
  }
}
