/* TEST BATTERY STYLES */

/* Container */

#test_battery_page {
    display: flex;
    flex-flow: column;
    height: 100%;
    padding-top: 1vh;
    padding-bottom: 1vh;
}

#test_battery_container {
    display: flex;
    flex-flow: column;
    height: 100%;
    flex-grow: 1;
    background-color: #f8f8ff;
    border-radius: 1vw;
    border: 0.1vw solid #515fc9;
    padding: 1.15vw 1vw 1.15vw 1vw;
}

/* Background image */

#test_battery_page_background_image {
    min-height: 100%;
    min-width: 1024px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.85;
}