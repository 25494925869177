/* FOOTER STYLES */

/* Footer container */

.widget_footer_container {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 0.125rem;
  padding-bottom: 0.25rem;
  background-color: #f8f8ff;
}

@media screen and (max-width: 992px) {
  #widget_footer_primary_container > div {
    padding: 0;
    margin-top: 0.5vw;
  }
}

/* Branding image */

@media screen and (min-width: 992px) {
  .widget_footer_brand_image > img {
    width: 10vw;
  }
}

@media screen and (max-width: 992px) {
  .widget_footer_brand_image {
    padding-left: 0.325vw;
  }
  .widget_footer_brand_image > img {
    width: 50vw;
  }
}

/* Footer addresses */

@media screen and (min-width: 992px) {
  .widget_footer_address_container {
    font-size: 0.9vw;
    padding-top: 1.625vw;
    text-align: center;
  }
  .widget_footer_address_container > ul {
    color: rgba(0, 0, 0, 0.5);
    list-style-type: none;
  }
}

@media screen and (max-width: 992px) {
  .widget_footer_address_container {
    margin-top: 2.5vw;
    width: 100%;
    margin-left: 0rem;
  }
  .widget_footer_address_container > ul {
    padding: 0;
    padding-left: 1vw;
    color: rgba(0, 0, 0, 0.5);
    font-size: 2.25vw;
    list-style-type: none;
  }
  .footer_address_container {
    margin: 0;
    padding: 0;
    padding-left: 1.05vw;
  }
}

/* Hyperlinks */

@media screen and (min-width: 992px) {
  .widget_footer_hyperlinks_container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  #widget_footer_contact_us_button {
    margin-top: 0.725vw;
    display: flex;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    font-size: 0.9vw;
    color: rgba(0, 0, 0, 0.5);
  }
  #widget_footer_contact_us_button_image {
    font-size: 1.35vw;
  }
  #widget_footer_contact_us_button:hover {
    color: #515fc9;
  }
  #widget_footer_contact_us_button_image {
    margin-left: 0.75vw;
  }
}

@media screen and (max-width: 992px) {
  .widget_footer_hyperlinks_container {
    padding-left: 1.05vw;
  }
  #widget_footer_contact_us_button {
    margin: 0;
    padding: 0;
    margin-top: 0.725vw;
    margin-bottom: 1vw;
    padding-left: 1.55vw;
    display: flex;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    font-size: 3vw;
    color: rgba(0, 0, 0, 0.5);
  }
  #widget_footer_contact_us_button_image {
    font-size: 3vw;
  }
  #widget_footer_contact_us_button:hover {
    color: #515fc9;
  }
  #widget_footer_contact_us_button_image {
    margin-left: 1.5vw;
  }
}

/* Twitter and LinkedIn buttons */

.widget_footer_hyperlink_outside_link {
  padding-top: 0.4vw;
  padding-left: 1.75vw;
  color: rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 992px) {
  .widget_footer_hyperlink_outside_link > svg {
    font-size: 1.5vw;
  }
}

@media screen and (max-width: 992px) {
  .widget_footer_hyperlink_outside_link > {
    padding-left: 1.15vw;
  }
  .widget_footer_hyperlink_outside_link > svg {
    margin-right: 2.5vw;
    font-size: 3vw;
  }
}

.widget_footer_hyperlink_outside_link:hover {
  color: #515fc9;
}

/* Footer secondary */

@media screen and (min-width: 992px) {
  #widget_footer_secondary_container {
    font-size: 0.8vw;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    padding-left: 1vw;
    color: rgba(0, 0, 0, 0.5);
  }
}

@media screen and (max-width: 992px) {
  #widget_footer_secondary_container {
    font-size: 1.75vw;
    margin-top: 3vw;
    margin-left: 0.7vw;
    padding-left: 1.15vw;
    color: rgba(0, 0, 0, 0.5);
  }
}

/* Contact us formulary modal styles */

#widget_footer_contact_us_formulary_send_button {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #515fc9;
  color: #f8f8ff;
  border-color: transparent;
  border-radius: 1.5rem;
  font-size: 1rem;
}

#widget_footer_contact_us_formulary_close_button {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f8f8ff;
  color: #515fc9;
  border-color: transparent;
  border-radius: 1.5rem;
  font-size: 1rem;
}

/* Privacy policy */
@media screen and (min-width: 992px) {
  #widget_footer_privacy_policy_button {
    border: none;
    text-transform: none;
    font-size: 0.8vw;
    margin-left: 1.25vw;
    padding: 0;
    color: rgba(0, 0, 0, 0.5);
  }
  #widget_footer_privacy_policy_explanation {
    font-family: "Montserrat", sans-serif;
    font-size: 0.9vw;
    line-height: 1.325vw;
  }
  #widget_footer_privacy_policy_button:hover {
    background-color: transparent;
    color: #515fc9;
  }
  .widget_footer_secondary_interpunct {
    margin-left: 1.25vw;
  }
}

@media screen and (max-width: 992px) {
  #widget_footer_privacy_policy_button {
    border: none;
    text-transform: none;
    font-size: 2vw;
    margin-left: 1.5vw;
    padding: 0;
    color: rgba(0, 0, 0, 0.5);
  }
  #widget_footer_privacy_policy_explanation {
    font-family: "Montserrat", sans-serif;
    font-size: 3.5vw;
    line-height: 4.25vw;
  }
  #widget_footer_privacy_policy_button:hover {
    background-color: transparent;
    color: #515fc9;
  }
  .widget_footer_secondary_interpunct {
    margin-left: 1.25vw;
  }
}

#widget_footer_privacy_policy_close_button {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f8f8ff;
  color: #515fc9;
  border-color: transparent;
  border-radius: 1.5rem;
  font-size: 1rem;
}

.MuiDialog-paperWidthSm {
  min-width: 95% !important;
  max-width: 95% !important;
}
