/* NAVIGATION BAR STYLES */

/* Navigation bar color */

.navbar {
    background-color: #f8f8ff;
}

/* Navigation bar items */

.nav-item {
    margin-right: 1em;
}

/* Navigation bar brand */

.navbar-brand {
    display: flex;
    align-items: center;
}

.navbar-brand>img {
    width: 7.75em;
    padding-right: 1em;
}

/* Language selection */

@media screen and (max-width: 992px) {
    .widget_navbar_language_settings_language_button {
        padding-left: 0;
        padding-right: 3.75vw;
    }
}

.widget_navbar_language_settings_language_button {
    color: #7f7f7f;    
}

.widget_navbar_language_settings_language_button:hover, .widget_navbar_language_settings_language_button:active {
    color: #515fc9;
}

.select_language_icon {
    max-height: 1.15em;
}

.widget_navbar_language_settings_language_button_selected {
    color: #515fc9;
}

/* NAVIGATION BAR ANIMATIONS */

/* Home */

@media screen and (min-width: 992px) {
    #widget_navbar_home_link:hover {
        color: #515fc9;
    }
    #widget_navbar_home_link:focus {
        color: #515fc9;
    }
    #widget_navbar_home_link::after {
        content: '';
        display: block;
        position: relative;
        top: 100%;
        height: 0.175rem;
        width: 100%;
        background-color: #515fc9;
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-transform: scale(0, 1);
        transform: scale(0, 1);
        transition: color 0.1s, -webkit-transform 0.2s ease-out;
        transition: color 0.1s, transform 0.2s ease-out;
        transition: color 0.1s, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
    }
    #widget_navbar_home_link:hover::after, #widget_navbar_home_link:focus::after {
        -webkit-transform-origin: center top;
        transform-origin: center top;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    #widget_navbar_home_link.active::after {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    #widget_navbar_home_link:hover {
        color: #515fc9;
    }
}

#widget_navbar_home_link {
    color: rgba(0, 0, 0, .5);
    text-decoration: none;
    position: relative;
    display: block;
    transition: color 0.1s, background-color 0.1s;
}

#widget_navbar_home_link.active {
    color: #515fc9;
    font-weight: 600;
}

/* Sign in */

@media screen and (min-width: 992px) {
    #widget_navbar_sign_in_link:hover {
        color: #515fc9;
    }
    #widget_navbar_sign_in_link:focus {
        color: #515fc9;
    }
    #widget_navbar_sign_in_link::after {
        content: '';
        display: block;
        position: relative;
        top: 100%;
        height: 0.175rem;
        width: 100%;
        background-color: #515fc9;
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-transform: scale(0, 1);
        transform: scale(0, 1);
        transition: color 0.1s, -webkit-transform 0.2s ease-out;
        transition: color 0.1s, transform 0.2s ease-out;
        transition: color 0.1s, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
    }
    #widget_navbar_sign_in_link:hover::after, #widget_navbar_sign_in_link:focus::after {
        -webkit-transform-origin: center top;
        transform-origin: center top;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    #widget_navbar_sign_in_link.active::after {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    #widget_navbar_sign_in_link:hover {
        color: #515fc9;
    }
}

#widget_navbar_sign_in_link {
    color: rgba(0, 0, 0, .5);
    text-decoration: none;
    position: relative;
    display: block;
    transition: color 0.1s, background-color 0.1s;
}

#widget_navbar_sign_in_link.active {
    color: #515fc9;
    font-weight: 600;
}

/* Profile */

@media screen and (min-width: 992px) {
    #widget_navbar_profile_link:hover {
        color: #515fc9;
    }
    #widget_navbar_profile_link:focus {
        color: #515fc9;
    }
    #widget_navbar_profile_link::after {
        content: '';
        display: block;
        position: relative;
        top: 100%;
        height: 0.175rem;
        width: 100%;
        background-color: #515fc9;
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-transform: scale(0, 1);
        transform: scale(0, 1);
        transition: color 0.1s, -webkit-transform 0.2s ease-out;
        transition: color 0.1s, transform 0.2s ease-out;
        transition: color 0.1s, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
    }
    #widget_navbar_profile_link:hover::after, #widget_navbar_profile_link:focus::after {
        -webkit-transform-origin: center top;
        transform-origin: center top;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    #widget_navbar_profile_link.active::after {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    #widget_navbar_profile_link:hover {
        color: #515fc9;
    }
}

#widget_navbar_profile_link {
    color: rgba(0, 0, 0, .5);
    text-decoration: none;
    position: relative;
    display: block;
    transition: color 0.1s, background-color 0.1s;
}

#widget_navbar_profile_link.active {
    color: #515fc9;
    font-weight: 600;
}

/* Sign out */

@media screen and (min-width: 992px) {
    #widget_navbar_sign_out_link:hover {
        color: #f7130a;
    }
    #widget_navbar_sign_out_link:focus {
        color: #f7130a;
    }
    #widget_navbar_sign_out_link::after {
        content: '';
        display: block;
        position: relative;
        top: 100%;
        height: 0.175rem;
        width: 100%;
        background-color: #f7130a;
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-transform: scale(0, 1);
        transform: scale(0, 1);
        transition: color 0.1s, -webkit-transform 0.2s ease-out;
        transition: color 0.1s, transform 0.2s ease-out;
        transition: color 0.1s, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
    }
    #widget_navbar_sign_out_link:hover::after, #widget_navbar_sign_out_link:focus::after {
        -webkit-transform-origin: center top;
        transform-origin: center top;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    #widget_navbar_sign_out_link.active::after {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    #widget_navbar_sign_out_link:hover {
        color: #f7130a;
    }
}

#widget_navbar_sign_out_link {
    color: rgba(0, 0, 0, .5);
    text-decoration: none;
    position: relative;
    display: block;
    transition: color 0.1s, background-color 0.1s;
}

#widget_navbar_sign_out_link.active {
    color: #f7130a;
    font-weight: 600;
}