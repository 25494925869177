/* TEST STEP COUNTER STYLES*/

@media screen and (min-width: 992px) {
    #widget_test_step_counter_container {
        padding-top: 1vw;
        padding-bottom: 1.5vw;
        width: 100%;
        justify-content: center;
    }
    .widget_test_step_counter_dot_step_container {
        width: 7.5%;
        text-align: center;
    }
    .widget_test_step_counter_current_dot {
        display: inline-block;
        width: 3.25vw;
        height: 3.25vw;
        background-color: #d8d8d8;
        border-radius: 50%;
        border: 0.25vw #5361c2 solid;
    }
    .widget_test_step_counter_empty_dot {
        display: inline-block;
        width: 3.25vw;
        height: 3.25vw;
        background-color: #d8d8d8;
        border-radius: 50%;
        border: 0.25vw #d8d8d8 solid;
    }
    .widget_test_step_counter_filled_dot {
        display: inline-block;
        width: 3.25vw;
        height: 3.25vw;
        background-color: #5361c2;
        border-radius: 50%;
        border: 0.25vw #5361c2 solid;
    }
}

@media screen and (max-width: 992px) {
    #widget_test_step_counter_container {
        padding-top: 1vw;
        padding-bottom: 3vw;
        width: 100%;
        justify-content: center;
    }
    .widget_test_step_counter_dot_step_container {
        width: 7.5%;
        text-align: center;
    }
    .widget_test_step_counter_current_dot {
        display: inline-block;
        width: 5.25vw;
        height: 5.25vw;
        background-color: #d8d8d8;
        border-radius: 50%;
        border: 0.425vw #5361c2 solid;
    }
    .widget_test_step_counter_empty_dot {
        display: inline-block;
        width: 5.25vw;
        height: 5.25vw;
        background-color: #d8d8d8;
        border-radius: 50%;
        border: 0.425vw #d8d8d8 solid;
    }
    .widget_test_step_counter_filled_dot {
        display: inline-block;
        width: 5.25vw;
        height: 5.25vw;
        background-color: #5361c2;
        border-radius: 50%;
        border: 0.425vw #5361c2 solid;
    }
}