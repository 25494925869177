/* TEST BATTERY CANCEL BUTTON STYLES */

@media screen and (min-width: 992px) {
    #test_battery_cancel_test_battery_button_container {
        height: 5vh;
    }
    #test_battery_cancel_test_battery_button {
        font-weight: 400;
        margin-right: 1.15vw;
        margin-bottom: 1vw;
        border: none;
        background-color: transparent;
        color: rgba(0, 0, 0, 0.5);
        font-size: 1.25vw;
    }
    #test_battery_cancel_test_battery_button:hover {
        color: #f7130a;
    }
}

@media screen and (max-width: 992px) {
    #test_battery_cancel_test_battery_button_container {
        height: 5vh;
    }
    #test_battery_cancel_test_battery_button {
        font-weight: 400;
        margin-right: 4.5vw;
        margin-bottom: 1vw;
        border: none;
        background-color: transparent;
        color: rgba(0, 0, 0, 0.5);
        font-size: 2.5vw;
    }
    #test_battery_cancel_test_battery_button:hover {
        color: #f7130a;
    }
}