/* TEST BATTERY RESULTS STYLES */

/* Container */

#test_battery_results_view_container {
  display: flex;
  flex-flow: column;
  height: 100%;
  flex: auto;
}

/* Header */

@media screen and (min-width: 992px) {
  #test_battery_results_header_container {
    margin: 0;
    width: 100%;
    padding-bottom: 1vw;
    border-bottom: 0.1vw solid #515fc9;
  }
  #test_battery_results_header_container > h1 {
    font-size: 4vw;
    font-weight: 500;
    color: #515fc9;
  }
}

@media screen and (max-width: 992px) {
  #test_battery_results_header_container {
    margin: 0;
    width: 100%;
    padding-bottom: 1.75vw;
    border-bottom: 0.05vw solid #515fc9;
  }
  #test_battery_results_header_container > h1 {
    font-size: 4vw;
    font-weight: 500;
    color: #515fc9;
  }
}

/* Explanation */

@media screen and (min-width: 992px) {
  #test_battery_results_explanation {
    margin: 0;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border-bottom: 0.05vw solid #515fc9;
  }
  .test_battery_results_text {
    margin: 0;
    color: rgba(0, 0, 0, 0.5);
  }
}

@media screen and (max-width: 992px) {
  #test_battery_results_explanation {
    margin: 0;
    padding-top: 1.5vw;
    padding-bottom: 1.5vw;
    border-bottom: 0.05vw solid #515fc9;
  }
  .test_battery_results_text {
    margin: 0;
    font-size: 2.5vw;
    color: rgba(0, 0, 0, 0.5);
  }
}

/* Table */

#test_battery_results_table_container {
  width: 100%;
  margin: 0;
  display: flex;
  flex-flow: column;
  height: 100%;
  flex: auto;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}

@media screen and (min-width: 992px) {
  /* Question1 */
  #test_battery_results_table_Question1_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Question1_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Question1_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Question1_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_Question1_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Question1_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_Question1_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Question1_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }

  /* Question2 */
  #test_battery_results_table_Question2_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Question2_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Question2_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Question2_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_Question2_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Question2_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_Question2_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Question2_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }

  /* Question3 */
  #test_battery_results_table_Question3_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Question3_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Question3_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Question3_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_Question3_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Question3_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_Question3_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Question3_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Question4 */
  #test_battery_results_table_Question4_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Question4_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Question4_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Question4_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_Question4_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Question4_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_Question4_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Question4_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Question5 */
  #test_battery_results_table_Question5_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Question5_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Question5_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Question5_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_Question5_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Question5_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_Question5_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Question5_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

/* Table header */

@media screen and (min-width: 992px) {
  #test_battery_results_table_header_cell_text_file_name {
    width: 30%;
    font-weight: 500;
    color: #515fc9;
    border-right: 0.001rem #d8d8d8 solid;
    font-size: 1vw;
  }
  #test_battery_results_table_header_cell_text_upload_progress {
    width: 60%;
    font-weight: 500;
    color: #515fc9;
    border-right: 0.001rem #d8d8d8 solid;
    font-size: 1vw;
  }
  #test_battery_results_table_header_cell_volume_icon {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    font-weight: 500;
    color: #515fc9;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_header_cell_volume_icon > svg {
    font-size: 1.575vw;
  }
  #test_battery_results_table_header_cell_upload_button_container {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    font-weight: 500;
    color: #515fc9;
  }
  #test_battery_results_table_header_cell_upload_button_container
    > button
    > svg {
    font-size: 1.75vw;
  }
}

@media screen and (max-width: 992px) {
  #test_battery_results_table_header_cell_text_file_name {
    width: 30%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    font-weight: 500;
    color: #515fc9;
    border-right: 0.001rem #d8d8d8 solid;
    font-size: 2.75vw;
  }
  #test_battery_results_table_header_cell_text_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    font-weight: 500;
    color: #515fc9;
    border-right: 0.001rem #d8d8d8 solid;
    font-size: 2.75vw;
  }
  #test_battery_results_table_header_cell_volume_icon {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    font-weight: 500;
    color: #515fc9;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_header_cell_volume_icon > svg {
    font-size: 4.5vw;
  }
  #test_battery_results_table_header_cell_upload_button_container {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    font-weight: 500;
    color: #515fc9;
  }
  #test_battery_results_table_header_cell_upload_button_container
    > button
    > svg {
    font-size: 4.5vw;
  }
}

/* Test step headings */

@media screen and (min-width: 992px) {
  #test_battery_results_table_CERAD_Codify_header {
    background-color: #d8d8d8;
    font-size: 1vw;
    font-weight: 600;
  }
  #test_battery_results_table_Conversation_header {
    background-color: #d8d8d8;
    font-size: 1vw;
    font-weight: 600;
  }
  #test_battery_results_table_Question1_header {
    background-color: #d8d8d8;
    font-size: 1vw;
    font-weight: 600;
  }
  #test_battery_results_table_CERAD_Remember_header {
    background-color: #d8d8d8;
    font-size: 1vw;
    font-weight: 600;
  }
  #test_battery_results_table_CERAD_Recognise_header {
    background-color: #d8d8d8;
    font-size: 1vw;
    font-weight: 600;
  }
  #test_battery_results_table_PVF_header {
    background-color: #d8d8d8;
    font-size: 1vw;
    font-weight: 600;
  }
  #test_battery_results_table_SVF_header {
    background-color: #d8d8d8;
    font-size: 1vw;
    font-weight: 600;
  }
  #test_battery_results_table_ASVF_header {
    background-color: #d8d8d8;
    font-size: 1vw;
    font-weight: 600;
  }
  #test_battery_results_table_Image_Description_header {
    background-color: #d8d8d8;
    font-size: 1vw;
    font-weight: 600;
  }
}

@media screen and (max-width: 992px) {
  #test_battery_results_table_CERAD_Codify_header {
    background-color: #d8d8d8;
    font-size: 2.75vw;
    font-weight: 600;
  }
  #test_battery_results_table_Conversation_header {
    background-color: #d8d8d8;
    font-size: 2.75vw;
    font-weight: 600;
  }
  #test_battery_results_table_Question1_header {
    background-color: #d8d8d8;
    font-size: 2.75vw;
    font-weight: 600;
  }
  #test_battery_results_table_CERAD_Remember_header {
    background-color: #d8d8d8;
    font-size: 2.75vw;
    font-weight: 600;
  }
  #test_battery_results_table_CERAD_Recognise_header {
    background-color: #d8d8d8;
    font-size: 2.75vw;
    font-weight: 600;
  }
  #test_battery_results_table_PVF_header {
    background-color: #d8d8d8;
    font-size: 2.75vw;
    font-weight: 600;
  }
  #test_battery_results_table_SVF_header {
    background-color: #d8d8d8;
    font-size: 2.75vw;
    font-weight: 600;
  }
  #test_battery_results_table_ASVF_header {
    background-color: #d8d8d8;
    font-size: 2.75vw;
    font-weight: 600;
  }
  #test_battery_results_table_Image_Description_header {
    background-color: #d8d8d8;
    font-size: 2.75vw;
    font-weight: 600;
  }
}

/* CERAD Codify */

@media screen and (min-width: 992px) {
  /* Test 1 */
  #test_battery_results_table_CERAD_Codify_01_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Codify_01_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Codify_01_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Codify_01_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Codify_01_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Codify_01_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Codify_01_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Codify_01_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Question1_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 2 */

  #test_battery_results_table_CERAD_Codify_02_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Codify_02_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Codify_02_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Codify_02_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Codify_02_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Codify_02_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Codify_02_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 3 */
  #test_battery_results_table_CERAD_Codify_03_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Codify_03_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Codify_03_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Codify_03_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Codify_03_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Codify_03_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Codify_03_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Codify_03_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

@media screen and (max-width: 992px) {
  /* Test 1 */
  #test_battery_results_table_CERAD_Codify_01_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Codify_01_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Codify_01_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Codify_01_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Codify_01_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Codify_01_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Codify_01_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Codify_01_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Codify_01_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 2 */
  #test_battery_results_table_CERAD_Codify_02_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Codify_02_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Codify_02_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Codify_02_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Codify_02_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Codify_02_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Codify_02_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Codify_02_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Codify_02_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 3 */
  #test_battery_results_table_CERAD_Codify_03_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Codify_03_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Codify_03_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Codify_03_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Codify_03_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Codify_03_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Codify_03_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Codify_03_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Codify_03_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

/* Questions */
@media screen and (min-width: 992px) {
  .test_battery_results_table_Question_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  .test_battery_results_table_Question_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  .test_battery_results_table_Question_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  .test_battery_results_table_Question_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    padding: 0;
  }
  .test_battery_results_table_Question_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  .test_battery_results_table_Question_button > button > svg {
    font-size: 1.75vw;
  }
  .test_battery_results_table_Question_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  .test_battery_results_table_Question_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

@media screen and (max-width: 992px) {
  .test_battery_results_table_Question_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  .test_battery_results_table_Question_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  .test_battery_results_table_Question_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  .test_battery_results_table_Question_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  .test_battery_results_table_Question_audio_stop > svg {
    font-size: 4vw;
  }
  .test_battery_results_table_Question_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  .test_battery_results_table_Question_audio_play > svg {
    font-size: 4vw;
  }
  .test_battery_results_table_Question_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  .test_battery_results_table_Question_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

/* Conversation */

@media screen and (min-width: 992px) {
  #test_battery_results_table_Conversation_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Conversation_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Conversation_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Conversation_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    padding: 0;
  }
  #test_battery_results_table_Conversation_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Conversation_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_Conversation_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Conversation_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

@media screen and (max-width: 992px) {
  #test_battery_results_table_Conversation_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Conversation_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Conversation_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Conversation_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Conversation_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_Conversation_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Conversation_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_Conversation_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_Conversation_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

/* CERAD Remember */

@media screen and (min-width: 992px) {
  #test_battery_results_table_CERAD_Remember_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Remember_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Remember_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Remember_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Remember_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Remember_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Remember_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Remember_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

@media screen and (max-width: 992px) {
  #test_battery_results_table_CERAD_Remember_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Remember_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Remember_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Remember_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Remember_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Remember_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Remember_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Remember_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Remember_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

/* CERAD Recognise */

@media screen and (min-width: 992px) {
  /* Test 1 */
  #test_battery_results_table_CERAD_Recognise_01_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_01_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_01_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_01_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_01_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_01_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_01_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_01_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 2 */
  #test_battery_results_table_CERAD_Recognise_02_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_02_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_02_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_02_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_02_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_02_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_02_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_02_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 3 */
  #test_battery_results_table_CERAD_Recognise_03_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_03_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_03_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_03_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_03_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_03_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_03_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_03_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 4 */
  #test_battery_results_table_CERAD_Recognise_04_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_04_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_04_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_04_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_04_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_04_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_04_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_04_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 5 */
  #test_battery_results_table_CERAD_Recognise_05_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_05_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_05_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_05_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_05_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_05_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_05_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_05_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 6 */
  #test_battery_results_table_CERAD_Recognise_06_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_06_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_06_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_06_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_06_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_06_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_06_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_06_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 7 */
  #test_battery_results_table_CERAD_Recognise_07_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_07_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_07_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_07_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_07_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_07_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_07_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_07_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 8 */
  #test_battery_results_table_CERAD_Recognise_08_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_08_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_08_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_08_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_08_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_08_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_08_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_08_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 9 */
  #test_battery_results_table_CERAD_Recognise_09_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_09_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_09_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_09_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_09_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_09_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_09_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_09_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 10 */
  #test_battery_results_table_CERAD_Recognise_10_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_10_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_10_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_10_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_10_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_10_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_10_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_10_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 11 */
  #test_battery_results_table_CERAD_Recognise_11_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_11_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_11_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_11_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_11_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_11_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_11_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_11_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 12 */
  #test_battery_results_table_CERAD_Recognise_12_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_12_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_12_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_12_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_12_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_12_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_12_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_12_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 13 */
  #test_battery_results_table_CERAD_Recognise_13_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_13_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_13_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_13_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_13_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_13_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_13_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_13_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 14 */
  #test_battery_results_table_CERAD_Recognise_14_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_14_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_14_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_14_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_14_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_14_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_14_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_14_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 15 */
  #test_battery_results_table_CERAD_Recognise_15_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_15_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_15_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_15_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_15_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_15_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_15_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_15_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 16 */
  #test_battery_results_table_CERAD_Recognise_16_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_16_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_16_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_16_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_16_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_16_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_16_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_16_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 17 */
  #test_battery_results_table_CERAD_Recognise_17_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_17_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_17_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_17_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_17_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_17_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_17_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_17_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 18 */
  #test_battery_results_table_CERAD_Recognise_18_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_18_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_18_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_18_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_18_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_18_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_18_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_18_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 19 */
  #test_battery_results_table_CERAD_Recognise_19_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_19_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_19_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_19_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_19_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_19_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_19_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_19_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 20 */
  #test_battery_results_table_CERAD_Recognise_20_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_20_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_20_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_20_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_20_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_20_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_CERAD_Recognise_20_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_20_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

@media screen and (max-width: 992px) {
  /* Test 1 */
  #test_battery_results_table_CERAD_Recognise_01_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_01_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_01_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_01_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_01_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_01_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_01_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_01_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_01_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 2 */
  #test_battery_results_table_CERAD_Recognise_02_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_02_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_02_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_02_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_02_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_02_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_02_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_02_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_02_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 3 */
  #test_battery_results_table_CERAD_Recognise_03_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_03_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_03_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_03_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_03_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_03_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_03_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_03_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_03_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 4 */
  #test_battery_results_table_CERAD_Recognise_04_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_04_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_04_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_04_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_04_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_04_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_04_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_04_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_04_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 5 */
  #test_battery_results_table_CERAD_Recognise_05_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_05_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_05_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_05_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_05_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_05_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_05_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_05_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_05_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 6 */
  #test_battery_results_table_CERAD_Recognise_06_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_06_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_06_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_06_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_06_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_06_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_06_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_06_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_06_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 7 */
  #test_battery_results_table_CERAD_Recognise_07_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_07_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_07_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_07_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_07_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_07_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_07_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_07_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_07_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 8 */
  #test_battery_results_table_CERAD_Recognise_08_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_08_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_08_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_08_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_08_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_08_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_08_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_08_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_08_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 9 */
  #test_battery_results_table_CERAD_Recognise_09_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_09_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_09_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_09_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_09_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_09_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_09_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_09_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_09_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 10 */
  #test_battery_results_table_CERAD_Recognise_10_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_10_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_10_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_10_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_10_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_10_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_10_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_10_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_10_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 11 */
  #test_battery_results_table_CERAD_Recognise_11_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_11_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_11_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_11_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_11_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_11_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_11_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_11_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_11_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 12 */
  #test_battery_results_table_CERAD_Recognise_12_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_12_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_12_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_12_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_12_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_12_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_12_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_12_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_12_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 13 */
  #test_battery_results_table_CERAD_Recognise_13_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_13_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_13_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_13_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_13_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_13_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_13_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_13_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_13_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 14 */
  #test_battery_results_table_CERAD_Recognise_14_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_14_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_14_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_14_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_14_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_14_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_14_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_14_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_14_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 15 */
  #test_battery_results_table_CERAD_Recognise_15_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_15_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_15_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_15_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_15_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_15_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_15_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_15_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_15_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 16 */
  #test_battery_results_table_CERAD_Recognise_16_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_16_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_16_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_16_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_16_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_16_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_16_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_16_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_16_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 17 */
  #test_battery_results_table_CERAD_Recognise_17_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_17_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_17_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_17_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_17_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_17_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_17_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_17_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_17_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 18 */
  #test_battery_results_table_CERAD_Recognise_18_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_18_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_18_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_18_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_18_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_18_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_18_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_18_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_18_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 19 */
  #test_battery_results_table_CERAD_Recognise_19_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_19_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_19_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_19_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_19_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_19_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_19_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_19_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_19_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  /* Test 20 */
  #test_battery_results_table_CERAD_Recognise_20_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_20_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_20_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_CERAD_Recognise_20_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_20_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_20_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_CERAD_Recognise_20_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_CERAD_Recognise_20_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_CERAD_Recognise_20_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

/* PVF */

@media screen and (min-width: 992px) {
  #test_battery_results_table_PVF_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_PVF_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_PVF_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_PVF_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_PVF_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_PVF_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_PVF_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_PVF_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

@media screen and (max-width: 992px) {
  #test_battery_results_table_PVF_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_PVF_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_PVF_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_PVF_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_PVF_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_PVF_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_PVF_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_PVF_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_PVF_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

/* SVF */

@media screen and (min-width: 992px) {
  #test_battery_results_table_SVF_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_SVF_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_SVF_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_SVF_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_SVF_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_SVF_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_SVF_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_SVF_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

@media screen and (max-width: 992px) {
  #test_battery_results_table_SVF_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_SVF_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_SVF_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_SVF_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_SVF_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_SVF_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_SVF_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_SVF_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_SVF_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

/* ASVF */

@media screen and (min-width: 992px) {
  #test_battery_results_table_ASVF_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_ASVF_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_ASVF_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_ASVF_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_ASVF_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_ASVF_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_ASVF_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_ASVF_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

@media screen and (max-width: 992px) {
  #test_battery_results_table_ASVF_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_ASVF_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_ASVF_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_ASVF_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_ASVF_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_ASVF_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_ASVF_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_ASVF_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_ASVF_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

/* Image Description */

@media screen and (min-width: 992px) {
  #test_battery_results_table_Image_Description_file_name {
    width: 30%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Image_Description_upload_progress {
    width: 60%;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Image_Description_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Image_Description_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_Image_Description_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Image_Description_button > button > svg {
    font-size: 1.75vw;
  }
  #test_battery_results_table_Image_Description_audio_stop > svg {
    font-size: 1.75vw;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Image_Description_audio_play > svg {
    font-size: 1.75vw;
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

@media screen and (max-width: 992px) {
  #test_battery_results_table_Image_Description_file_name {
    font-size: 2.75vw;
    width: 30%;
    padding-right: 0;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Image_Description_upload_progress {
    width: 60%;
    padding: 0;
    padding-left: 1vw;
    padding-right: 1vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Image_Description_audio {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
    border-right: 0.001rem #d8d8d8 solid;
  }
  #test_battery_results_table_Image_Description_audio_stop {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Image_Description_audio_stop > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_Image_Description_audio_play {
    color: green;
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #test_battery_results_table_Image_Description_audio_play > svg {
    font-size: 4vw;
  }
  #test_battery_results_table_Image_Description_button {
    width: 5%;
    padding: 0vw 1.5vw 0vw 1.5vw;
  }
  #test_battery_results_table_Image_Description_button > button {
    color: #515fc9;
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

/* Button */

@media screen and (min-width: 992px) {
  #test_battery_results_button_container {
    margin: 0;
    width: 100%;
    padding-top: 1vw;
    border-top: 0.1vw solid #515fc9;
  }
  #test_battery_results_button {
    font-size: 2.625vw;
    font-weight: 500;
    background-color: #515fc9;
    color: #f8f8ff;
    padding: 1vh 2.625vw 1vh 2.625vw;
    border-radius: 5vw;
  }
  #test_battery_results_button:hover {
    background-color: #f8f8ff;
    color: #515fc9;
  }
}

@media screen and (max-width: 992px) {
  #test_battery_results_button_container {
    margin: 0;
    width: 100%;
    padding-top: 1.75vw;
    border-top: 0.1vw solid #515fc9;
  }
  #test_battery_results_button {
    font-size: 4.75vw;
    font-weight: 500;
    background-color: #515fc9;
    color: #f8f8ff;
    padding: 1.025vh 5.25vw 1.025vh 5.25vw;
    border-radius: 10vw;
  }
  #test_battery_results_button:hover {
    background-color: #f8f8ff;
    color: #515fc9;
  }
}

/* Progress bar colors */

.progress {
  border-radius: 5rem;
}

.bg-progress_bar_success {
  background-color: #239364;
  border-radius: 5rem;
}

.bg-progress_bar_error {
  background-color: #8c1919;
  border-radius: 5rem;
}

.bg-progress_bar_minus_0 {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5rem;
}

.bg-progress_bar_0_5 {
  background-color: #fa9252;
  border-radius: 5rem;
}

.bg-progress_bar_5_10 {
  background-color: #fa9b51;
  border-radius: 5rem;
}

.bg-progress_bar_10_15 {
  background-color: #faa551;
  border-radius: 5rem;
}

.bg-progress_bar_15_20 {
  background-color: #faaf51;
  border-radius: 5rem;
}

.bg-progress_bar_20_25 {
  background-color: #fab951;
  border-radius: 5rem;
}

.bg-progress_bar_25_30 {
  background-color: #fac351;
  border-radius: 5rem;
}

.bg-progress_bar_30_35 {
  background-color: #facd51;
  border-radius: 5rem;
}

.bg-progress_bar_35_40 {
  background-color: #fad751;
  border-radius: 5rem;
}

.bg-progress_bar_40_45 {
  background-color: #fae050;
  border-radius: 5rem;
}

.bg-progress_bar_45_50 {
  background-color: #e8da51;
  border-radius: 5rem;
}

.bg-progress_bar_50_55 {
  background-color: #d6d353;
  border-radius: 5rem;
}

.bg-progress_bar_55_60 {
  background-color: #c4cd55;
  border-radius: 5rem;
}

.bg-progress_bar_60_65 {
  background-color: #b2c657;
  border-radius: 5rem;
}

.bg-progress_bar_65_70 {
  background-color: #a0c058;
  border-radius: 5rem;
}

.bg-progress_bar_70_75 {
  background-color: #8eba5a;
  border-radius: 5rem;
}

.bg-progress_bar_75_80 {
  background-color: #7db45b;
  border-radius: 5rem;
}

.bg-progress_bar_80_85 {
  background-color: #6bad5d;
  border-radius: 5rem;
}

.bg-progress_bar_85_90 {
  background-color: #59a75f;
  border-radius: 5rem;
}

.bg-progress_bar_90_95 {
  background-color: #47a061;
  border-radius: 5rem;
}

.bg-progress_bar_95_plus {
  background-color: #359a62;
  border-radius: 5rem;
}

/* Watch picture viewer modal view */

#test_battery_results_watch_picture_viewer {
  height: 80vh;
  width: 50vw;
}

#test_battery_results_watch_picture_viewer > .MuiDialogContent-root {
  height: 87.5%;
  width: 100%;
}

#test_battery_results_watch_picture_viewer > .MuiDialogActions-root {
  height: 12.5%;
  width: 100%;
}

#test_results_modal_play_button {
  font-size: 2vw;
  font-weight: 500;
  background-color: #515fc9;
  color: #f8f8ff;
  padding: 0.85vh 2.25vw 0.85vh 2.25vw;
  border-radius: 5vw;
  width: 17.5vw;
  border-color: transparent;
}

#test_results_modal_close_button {
  font-size: 2vw;
  font-weight: 500;
  background-color: #f8f8ff;
  color: #515fc9;
  padding: 0.85vh 2.25vw 0.85vh 2.25vw;
  border-radius: 5vw;
  width: 17.5vw;
  border-color: transparent;
}
