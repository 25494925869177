/* PATIENT DETAILS (MEDICAL STAFF VERSION) STYLES */

/* Container */

#profile_page_medical_staff_version_navigation_patient_profile_details_container {
    display: flex;
    flex-flow: column;
    flex: auto;
    height: 100%;
    width: 100%;
    outline: 0.175vw solid #515fc9;
}

/* Header */

#profile_medical_staff_version_patient_details_header_container {
    font-family: 'Montserrat', sans-serif;
    padding-top: 0.425vw;
    padding-bottom: 0.425vw;
    font-size: 2vw;
    font-weight: 600;
    color: #515fc9;
    border-bottom: 0.05vw solid #515fc9;
}

/* Test battery information */

#profile_medical_staff_version_patient_details_information_container {
    padding-top: 0.5vw;
    padding-bottom: 0.75vw;
    border-bottom: 0.05vw solid #515fc9;
}

#profile_medical_staff_version_patient_details_information_header {
    font-family: 'Montserrat', sans-serif;
    padding-top: 0.25vw;
    padding-bottom: 0.625vw;
    font-size: 1.325vw;
    font-weight: 600;
    color: #515fc9;
}

#profile_medical_staff_version_patient_details_information_table_date {
    font-weight: 500;
    color: #515fc9;
    border-right: 0.001rem #d8d8d8 solid;
    font-size: 1vw;
}

#profile_medical_staff_version_patient_details_information_table_gender {
    font-weight: 500;
    color: #515fc9;
    border-right: 0.001rem #d8d8d8 solid;
    font-size: 1vw;
}

#profile_medical_staff_version_patient_details_information_table_level_studies {
    font-weight: 500;
    color: #515fc9;
    border-right: 0.001rem #d8d8d8 solid;
    font-size: 1vw;
}

/* Start test battery button */

#profile_medical_staff_version_patient_details_start_battery_button_container {
    padding-top: 1vw;
    padding-bottom: 1vw;
    border-bottom: 0.05vw solid #515fc9;
}

#profile_medical_staff_version_patient_details_start_battery_button {
    padding: 0.525vw 1.75vw 0.525vw 1.75vw;
    background-color: #515fc9;
    font-size: 1.5vw;
    font-weight: 500;
    color: #f8f8ff;
    border-radius: 5vw;
    border: 0.125vw solid #515fc9;
}

#profile_medical_staff_version_patient_details_start_battery_button:hover {
    padding: 0.525vw 1.75vw 0.525vw 1.75vw;
    background-color: #f8f8ff;
    font-size: 1.5vw;
    font-weight: 500;
    color: #515fc9;
    border-radius: 5vw;
    border: 0.125vw solid #515fc9;
}

/* Test battery list */

#profile_medical_staff_version_patient_details_test_battery_list_header {
    font-family: 'Montserrat', sans-serif;
    padding-top: 0.25vw;
    padding-bottom: 0.625vw;
    font-size: 1.325vw;
    font-weight: 600;
    color: #515fc9;
}

#profile_medical_staff_version_patient_details_test_battery_list_container {
    flex: auto;
    height: 100%;
    padding-bottom: 1vw;
}

#profile_medical_staff_version_patient_details_test_battery_list_parent_container {
    display: table;
    height: 100%;
    width: 100%;
    background-color: white;
    outline: 0.175vw solid #515fc9;
}

#profile_medical_staff_version_patient_details_test_battery_list_child_container {
    display: table-cell;
    height: 100%;
}

#profile_medical_staff_version_patient_details_test_battery_list_subparent_container {
    display: block;
    height: 100%;
    width: 100%;
    display: table;
}

#profile_medical_staff_version_patient_details_test_battery_list_button_container {
    width: 100%;
    height: 5%;
    display: table-row;
    background-color: #EAF8FF;
    text-align: end;
}

#profile_medical_staff_version_patient_details_test_battery_list_button_delete {
    font-size: 1.5vw;
    padding-top: 0.325vw;
    padding-bottom: 0.325vw;
    margin-right: 0.325vw;
    background-color: transparent;
    border: none;
}

#profile_medical_staff_version_patient_details_test_battery_list {
    width: 100%;
    height: 95%;
    display: table-row;
}

#profile_page_patient_version_navigation_profile_list_content_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

#profile_page_patient_version_navigation_profile_list_content {
    overflow-y: scroll;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

#profile_page_patient_version_navigation_profile_list_content>ul {
    list-style: none;
    padding: 0;
}

#profile_page_patient_version_navigation_profile_list_content>ul>li {
    font-size: 1.15vw;
    font-family: 'Montserrat', sans-serif;
    padding: 0.5vw 0.75vw 0.5vw 0.75vw;
    border-bottom: 0.01vw solid #515fc9;
}

.profile_page_patient_version_navigation_profile_list_content:hover {
    cursor: pointer;
}

/* TEST BATTERY DETAILS */

/* Container */

#profile_page_patient_version_navigation_profile_details_container {
    display: flex;
    flex-flow: column;
    flex: auto;
    height: 100%;
    width: 100%;
    outline: 0.175vw solid #515fc9;
}

/* Back button */

#profile_patient_test_battery_details_back_button {
    color: #515fc9;
    font-size: 2vw;
    margin-top: 0.5vw;
    background-color: transparent;
    border: none;
}

/* Header */

#profile_page_patient_version_navigation_profile_test_battery_details_container {
    font-family: 'Montserrat', sans-serif;
    padding-top: 0.425vw;
    padding-bottom: 0.425vw;
    font-size: 2vw;
    font-weight: 600;
    color: #515fc9;
    border-bottom: 0.05vw solid #515fc9;
}

/* Test battery information */

#profile_page_patient_version_navigation_profile_test_battery_information_container {
    padding-top: 0.5vw;
    padding-bottom: 0.75vw;
    border-bottom: 0.05vw solid #515fc9;
}

#profile_page_patient_version_navigation_profile_test_battery_information_container > div:first-child,
#profile_page_patient_version_navigation_profile_test_battery_information_container > div:last-child {
    padding-left: 2vw;
}

img[id^="patient_score_image"] {
    max-height: 159px;
    min-height: 132px;
}

#profile_page_patient_version_navigation_profile_test_battery_information_header {
    font-family: 'Montserrat', sans-serif;
    padding-top: 0.25vw;
    padding-bottom: 0.625vw;
    font-size: 1.325vw;
    font-weight: 600;
    color: #515fc9;
}

#profile_page_patient_version_navigation_profile_test_battery_information_date {
    font-weight: 500;
    color: #515fc9;
    border-right: 0.001rem #d8d8d8 solid;
    font-size: 1vw;
}

#profile_page_patient_version_navigation_profile_test_battery_information_completed {
    font-weight: 500;
    color: #515fc9;
    border-right: 0.001rem #d8d8d8 solid;
    font-size: 1vw;
}

#profile_page_patient_version_navigation_profile_test_battery_information_uploaded {
    font-weight: 500;
    color: #515fc9;
    border-right: 0.001rem #d8d8d8 solid;
    font-size: 1vw;
}

#profile_page_patient_version_navigation_profile_test_battery_information_result {
    font-weight: 500;
    color: #515fc9;
    border-right: 0.001rem #d8d8d8 solid;
    font-size: 1vw;
}

#profile_page_patient_version_navigation_profile_test_battery_information_show_score_button {
    background-color: transparent;
    color: #515fc9;
    padding: 0 1vw 0 1vw;
    border: none;
}

#profile_page_patient_version_navigation_profile_test_battery_information_show_score_button:hover {
    background-color: #515fc9;
    color: #d8d8d8;
    padding: 0 1vw 0 1vw;
    border: none;
}

.profile_page_patient_version_navigation_profile_test_battery_information_score_modal_text {
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
    font-size: 1.15vw;
    color: rgba(0, 0, 0, 0.5);
}

#profile_page_patient_version_navigation_profile_test_battery_information_score_modal_close_button {
    font-size: 1.5vw;
    font-weight: 500;
    background-color: #f8f8ff;
    color: #515fc9;
    padding: 0.625vh 2.25vw 0.625vh 2.25vw;
    border-radius: 5vw;
    border-color: transparent;
}

#profile_page_patient_version_navigation_profile_test_battery_information_score_modal_close_button:hover {
    font-size: 1.5vw;
    font-weight: 500;
    background-color: #515fc9;
    color: #f8f8ff;
    padding: 0.625vh 2.25vw 0.625vh 2.25vw;
    border-radius: 5vw;
    border-color: transparent;
}

/* File list container */

#profile_page_patient_version_navigation_profile_test_battery_file_list_header {
    font-family: 'Montserrat', sans-serif;
    padding-top: 0.25vw;
    padding-bottom: 0.625vw;
    font-size: 1.325vw;
    font-weight: 600;
    color: #515fc9;
}

#profile_page_patient_version_navigation_profile_test_battery_file_container {
    flex: auto;
    height: 100%;
}