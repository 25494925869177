/* IMAGE DESCRIPTION TEST STEP STYLES */

/* Container */

#test_battery_test_step_09_ImageDescription_container {
    display: flex;
    flex-flow: column;
    height: 100%;
    flex: auto;
}

/* Image container */

#test_battery_test_step_09_ImageDescription_image_container {
    height: 60%;
    margin: 0;
    margin-left: 0.5vw;
    border-bottom: 0.1vw solid #515fc9;
}

#test_battery_test_step_09_ImageDescription_image_container>img {
    max-height: 100%;
    max-width: 100%;
}

/* Recording button */

#test_battery_test_step_09_ImageDescription_button_container {
    margin: 0;
    height: 20%;
    padding-top: 1vw;
    border-top: 0.1vw solid #515fc9;
}

@media screen and (min-width: 992px) {
    #test_battery_test_step_09_ImageDescription_stop_button {
        background-color: transparent;
        border: none;
    }
    #test_battery_test_step_09_ImageDescription_stop_button>svg {
        font-size: 7em;
        color: #f7130a;
    }
    #test_battery_test_step_09_ImageDescription_stop_button>svg:hover {
        font-size: 7em;
        color: #910000;
    }
    #test_battery_test_step_09_ImageDescription_stop_button_interstitial {
        font-size: 7em;
        color: transparent;
        background-color: transparent;
        border: none;
    }
    #test_battery_test_step_09_ImageDescription_stop_button_interstitial:hover {
        font-size: 7em;
        background-color: transparent;
        color: transparent;
        border: none;
    }
    #test_battery_test_step_09_ImageDescription_stop_button_interstitial>svg:active {
        font-size:7em;
        background-color: transparent;
        color: transparent;
        border: none;
    }
}

@media screen and (max-width: 992px) {
    #test_battery_test_step_09_ImageDescription_stop_button {
        background-color: transparent;
        border: none;
    }
    #test_battery_test_step_09_ImageDescription_stop_button>svg {
        font-size: 7em;
        color: #f7130a;
    }
    #test_battery_test_step_09_ImageDescription_stop_button>svg:hover {
        font-size: 7em;
        color: #910000;
    }
    #test_battery_test_step_09_ImageDescription_stop_button_interstitial>svg {
        font-size: 7em;
        color: transparent;
        background-color: transparent;
        border: none;
    }
    #test_battery_test_step_09_ImageDescription_stop_button_interstitial>svg:hover {
        font-size: 7em;
        background-color: transparent;
        color: transparent;
        border: none;
    }
    #test_battery_test_step_09_ImageDescription_stop_button_interstitial>svg:active {
        font-size: 7em;
        background-color: transparent;
        color: transparent;
        border: none;
    }
}