/* PREVENTION STYLES */

/* Container */

#home_prevention_section_row {
    background-color: rgba(97, 110, 200, 1);
}

#home_prevention_section_container {
    margin-top: 2.5vw;
    margin-bottom: 3.5vw;
}

/* Section header */

@media screen and (min-width: 992px) {
    #home_prevention_section_header_container {
        margin-bottom: 1.75vw;
    }
    #home_prevention_section_container_header {
        position: relative;
        padding-left: 1.525vw;
        margin-left: 1vw;
        margin-right: 1vw;
        font-family: 'Montserrat', sans-serif;
        font-size: 4vw;
        font-weight: 600;
        color: #f8f8ff;
        text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
    }
    #home_prevention_section_container_header:after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        bottom: 0;
        width: 0.325vw;
        background: #f8f8ff;
        border-radius: 1rem;
        box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
    }
}

@media screen and (max-width: 992px) {
    #home_prevention_section_header_container {
        margin-bottom: 2.25vw;
    }
    #home_prevention_section_container_header {
        position: relative;
        padding-left: 2.625vw;
        margin-left: 1vw;
        margin-right: 1vw;
        font-family: 'Montserrat', sans-serif;
        font-size: 4.75vw;
        font-weight: 600;
        color: #f8f8ff;
        text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
    }
    #home_prevention_section_container_header:after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        bottom: 0;
        width: 0.625vw;
        background: #f8f8ff;
        border-radius: 1rem;
        box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
    }
}

/* Text container */

@media screen and (min-width: 992px) {
    #home_prevention_section_text_container {
        border-left: 0.325vw solid #e1e5f4;
        margin-left: 1vw;
        margin-right: 1vw;
        padding-left: 1vw;
        padding-right: 1vw;
        background-color: #4d60c0;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12), 0 16px 16px rgba(0, 0, 0, 0.12);
    }
    #home_prevention_section_explanation {
        margin-top: 2.25vw;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.525vw;
        color: #f8f8ff;
        line-height: 2.5vw;
        text-align: justify;
    }
}

@media screen and (max-width: 992px) {
    #home_prevention_section_text_container {
        border-left: 0.625vw solid #e1e5f4;
        margin-left: 1vw;
        margin-right: 1vw;
        background-color: #4d60c0;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12), 0 16px 16px rgba(0, 0, 0, 0.12);
    }
    #home_prevention_section_explanation {
        margin-top: 2.25vw;
        font-family: 'Montserrat', sans-serif;
        font-size: 3vw;
        color: #f8f8ff;
        line-height: 4.25vw;
        text-align: justify;
    }
}

/* Panels */

@media screen and (min-width: 992px) {
    #home_prevention_section_panel_container {
        margin-top: 3.25vw;
        margin-bottom: 2.75vw;
    }
    .home_prevention_section_panel_icon_container {
        flex: 1;
        width: 100%;
        height: 70%;
        position: relative;
    }
    .home_prevention_section_icon_container {
        flex: 1;
        height: auto;
    }
    .home_prevention_section_icon_container>svg {
        font-size: 17.5vw;
        color: #f8f8ff;
        border: 0.3vw solid white;
        padding: 2vw;
        border-radius: 40vw;
    }
    .home_prevention_section_panel_text {
        flex: 1;
        height: 30%;
        padding-top: 4vw;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.375vw;
        color: #f8f8ff;
        line-height: 2.5vw;
        text-align: justify;
        padding-left: 1vw;
        padding-right: 1vw;
    }
    .home_prevention_section_panel_number {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 5vw;
        font-size: 3vw;
        background-color: #f8f8ff;
        color: #515fc9;
        width: 5vw;
        height: 5vw;
        border-radius: 50%;
        font-weight: 600;
        vertical-align: middle;
    }
}

@media screen and (max-width: 992px) {
    #home_prevention_section_panel_container {
        margin-top: 3.25vw;
        margin-bottom: 2.75vw;
    }
    .home_prevention_section_panel_icon_container {
        margin-bottom: 7.5vw;
        width: 100%;
        height: 70%;
    }
    .home_prevention_section_icon_container {
        flex: 1;
        height: auto;
    }
    .home_prevention_section_icon_container>svg {
        font-size: 30vw;
        color: #f8f8ff;
        border: 0.5vw solid white;
        padding: 3.25vw;
        border-radius: 40vw;
    }
    .home_prevention_section_panel_text {
        flex: 1;
        height: 30%;
        padding-top: 4vw;
        font-family: 'Montserrat', sans-serif;
        font-size: 3vw;
        color: #f8f8ff;
        line-height: 3.75vw;
        text-align: justify;
    }
    .home_prevention_section_panel_number {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        margin-left: 20.5vw;
        font-size: 5vw;
        background-color: #f8f8ff;
        color: #515fc9;
        width: 7.5vw;
        height: 7.5vw;
        border-radius: 50%;
        font-weight: 600;
        vertical-align: middle;
    }
}